/* eslint-disable no-unused-vars */
import ElementModifier from './ElementModifier'

export default class LockModifier extends ElementModifier {
  get color() {
    return 'coral'
  }

  get coordinates() {
    // TODO: make this better
    const center = 44 * this.scale
    // const lgOffset = 6
    // const smOffset = center / 2
    return [center, center]
  }

  get scale() {
    return 0.15
  }

  get path() {
    return 'M210.59,247H46c-3.06-1.18-5.83-2.72-7.78-5.52-2.76-3.95-3.56-8.39-3.56-13.07q0-54,0-107.95a37.8,37.8,0,0,1,.36-5.21c1.15-8.46,6.75-13.36,15.26-13.41h2.4c0-6.86-.21-13.37.06-19.85.24-5.92.39-11.95,1.62-17.71C58.92,42.7,70.53,25.76,89.42,14.21c17.79-10.88,37.06-13.3,57.2-8.54,16.2,3.82,29.46,12.59,39.94,25.45a73.36,73.36,0,0,1,16.91,42.56c.51,8.45.3,16.95.42,25.42,0,.85,0,1.69,0,2.74h4.3a11.7,11.7,0,0,1,9,4.07c3.4,3.72,4.68,8.25,4.69,13.12q.07,55.28,0,110.57C221.85,237.62,218.83,244.11,210.59,247Zm-38-145.27c0-7.58,0-14.94,0-22.3a44.69,44.69,0,0,0-9.31-27.15A44,44,0,0,0,116.8,36.45C100,40.92,86.33,56,84.63,73.21c-.92,9.27-.43,18.67-.56,28,0,.13.14.27.27.51Zm-59.36,89.86c0,5,0,10,0,15,0,5.71,2.79,8.49,8.45,8.51q6.54,0,13.07,0c5.87,0,8.62-2.73,8.63-8.57,0-8.32.05-16.64,0-25,0-4,.31-7.56,2.8-11.28,5.05-7.54,3.89-16.69-1.89-23.82-4.92-6.06-14.83-8.68-22.8-6a20.46,20.46,0,0,0-9.54,32.34,5.9,5.9,0,0,1,1.22,3.38C113.26,181.28,113.19,186.44,113.19,191.59Z'
  }
}
