<template>
  <div class="auth-background">
    <v-card class="auth-ctr">
      <LogoCard/>
      <v-form ref="createAccountForm" id="create-account-form" v-model='isValid' @submit.prevent='createAccount'>
        <v-text-field
          v-model='email'
          label='Email'
          hint='Enter your email address.'
          :rules="[formRules.generic, formRules.email]"
          required
        ></v-text-field>
        <PasswordField
          type='primary'
          :show='show'
          v-model='primaryPassword'
          @toggle-show='toggleShow'
        />
        <PasswordField
          type='secondary'
          :show='show'
          v-model='secondaryPassword'
          @toggle-show='toggleShow'
        />
        <v-checkbox
          v-model='isAdult'
          :rules="[formRules.generic]"
          label='I am 18 years of age or older'
          required
        ></v-checkbox>
        <v-btn
          type='submit'
          form='create-account-form'
          :disabled='!isValid'
          color='primary'
        >Create New Account</v-btn>
      </v-form>
      <div class='spacer'>or</div>
      <div class='login-wrapper'>
        <v-btn class='login-btn' to='/login/' color='secondary'>Log In</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import has from 'lodash/has'
import { mapActions, mapGetters } from 'vuex'
import aws from '@/auth/aws'
import PasswordField from '@/components/auth/PasswordField.vue'
import LogoCard from '@/components/LogoCard.vue'
import { routeNames } from '../../vars'

export default {
  name: 'CreateAccount',
  components: {
    PasswordField,
    LogoCard
  },
  data() {
    return {
      show: false,
      isValid: false
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    toggleShow() {
      this.show = !this.show
    },
    createAccount() {
      this.enableLoadingDialog()
      aws.createAccount(this.serializedAccount)
        .then((res) => {
          this.disableLoadingDialog()
          let email = JSON.parse(JSON.stringify(this.email))
          if (has(res, 'user') && has(res.user, 'username')) {
            email = res.user.username
          }
          const message = `Account created successfuly. A link has been sent to ${email}.
          Please click the provided link to verify your email and then log in the application.`
          this.showMessage(message)
          this.$router.push({ name: routeNames.LOGIN })
        })
        .catch((error) => {
          this.disableLoadingDialog()
          const message = `An error occured: ${error.message}`
          this.showMessage(message)
          this.$refs.createAccountForm.reset()
        })
    },
  },
  computed: {
    ...mapGetters(['accountEmail', 'accountDidConsent', 'accountPrimaryPassword', 'accountSecondaryPassword', 'accountIsAdult', 'serializedAccount']),
    isAdult: {
      get() {
        return this.accountIsAdult
      },
      set(value) {
        this.$store.commit('setAccountValueByKey', { key: 'isAdult', value })
      }
    },
    email: {
      get() {
        return this.accountEmail
      },
      set(value) {
        this.$store.commit('setAccountValueByKey', { key: 'email', value })
      }
    },
    primaryPassword: {
      get() {
        return this.accountPrimaryPassword
      },
      set(value) {
        this.$store.commit('setAccountValueByKey', { key: 'primaryPassword', value })
      }
    },
    secondaryPassword: {
      get() {
        return this.accountSecondaryPassword
      },
      set(value) {
        this.$store.commit('setAccountValueByKey', { key: 'secondaryPassword', value })
      }
    },
    // TODO: incorporate this
    passwordConfirmRule() {
      return () => (this.primaryPassword === this.secondaryPassword) || 'Passwords must match.'
    }
  }
}
</script>

<style scoped>
  #create-account-form {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
