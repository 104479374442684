import { operatingSystems, browsers } from '../../vars'

function getOperatingSystem() {
  let userOS = null
  for (const os in operatingSystems) {
    if (navigator.appVersion.indexOf(operatingSystems[os]) !== -1) {
      userOS = operatingSystems[os]
    }
  }
  return userOS
}

function getBrowser() {
  let userBrowser = null
  for (const browser in browsers) {
    if (navigator.userAgent.indexOf(browsers[browser]) !== -1) {
      userBrowser = browsers[browser]
    }
  }
  return userBrowser
}

const getDefaultState = () => ({
  operatingSystem: getOperatingSystem(),
  browser: getBrowser()
})

const state = getDefaultState()
const getters = {
  operatingSystem: (state) => state.operatingSystem,
  browser: (state) => state.browser
}
const actions = {
  setOperatingSystem: ({ commit }, os) => {
    commit('setOperatingSystem', os)
  },
  setBrowser: ({ commit }, browser) => {
    commit('setBrowser', browser)
  }
}
const mutations = {
  setOperatingSystem: (state, os) => {
    state.operatingSystem = os
  },
  setBrowser: (state, browser) => {
    state.browser = browser
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
