<template>
  <div class='logo-ctr'>
    <div ref='appTitle' class='app-title'>Parallel</div>
  </div>
</template>

<script>
export default {
  name: 'LogoCard'
}
</script>

<style scoped>
.app-title {
  font-weight: 700;
  font-size: 2rem;
}
.logo-ctr {
  padding: 1rem;
  background-color: white;
}

</style>
