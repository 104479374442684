/* eslint-disable prefer-const */
import PathElement from './PathElement'
import { elementType } from '../vars'

export default class Link extends PathElement {
  constructor(data) {
    super(data)
    this.sourceEl = data.sourceEl
    this.targetEl = data.targetEl
    this.type = elementType.LINK
  }

  get sourceEl() {
    return this.data.sourceEl
  }

  set sourceEl(el) {
    this.data.sourceEl = el
  }

  get targetEl() {
    return this.data.targetEl
  }

  set targetEl(el) {
    this.data.targetEl = el
  }

  isLinkElement() {
    return true
  }

  serialize() {
    return {
      delivered: this.delivered,
      isLinkElement: true,
      type: this.type,
      id: this.id,
      sourceEl: this.sourceEl,
      targetEl: this.targetEl,
      spec: this.spec
    }
  }

  // eslint-disable-next-line no-unused-vars
  draw(ctx) {
    ctx.save()
    const y1 = this.sourceEl.coordinates[0] + this.sourceEl.radius
    const x1 = this.sourceEl.coordinates[1] + this.sourceEl.radius
    if (!this.targetEl) console.error('targetEl undefined')
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    let y2 = this.targetEl.coordinates[0]
    let x2 = this.targetEl.coordinates[1]
    // The mouse element should not be transformed or scaled, but when this draw function is called,
    // it is already transformed and scaled, transform/scale it back
    if (this.targetEl.type === elementType.MOUSE) {
      let { y, x, k } = this.targetEl.transform
      x2 -= x
      y2 -= y
      x2 /= k
      y2 /= k
    } else {
      x2 += this.targetEl.radius
      y2 += this.targetEl.radius
    }
    // TODO: scale curve multiplier based on distance
    ctx.quadraticCurveTo(x1 * 1.2, y1 * 1.2, x2, y2)
    ctx.strokeStyle = 'yellow'
    ctx.lineWidth = 5
    ctx.stroke()
    ctx.restore()
  }
}
