<template>
  <div id='player-graph-mount-node' ref='playerGraphMountNode'>
    <div v-if='snapshots.length'>
      <svg :width='totalWidth' :height='totalHeight' id='player-graph'>
      </svg>
    </div>
    <div id='player-graph-tooltip'>
      <div>{{selectedNodeText}}</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import Vue from 'vue'

export default {
  name: 'PlayerGraph',
  props: {
    snapshots: {
      required: false,
      type: Array,
    }
  },
  data() {
    return {
      totalHeight: 0,
      totalWidth: 0,
      selectedNode: null,
      margin: { top: 50, right: 10, bottom: -20, left: 10 },
      maxY: 10,
    }
  },
  methods: {
    setSelectedNode(node) {
      Vue.set(this, 'selectedNode', node)
    },
    renderChart() {
      const svg = d3.select('#player-graph')
      svg.append('path')
        .datum(this.formattedSnapshots)
        .attr('fill', 'none')
        .attr('stroke', 'coral')
        .attr('stroke-width', 2)
        .attr('class', 'line')
        .attr('d', this.lineGenerator)

      svg
        .append('g')
        .selectAll('node')
        .data(this.formattedSnapshots)
        .enter()
        .append('circle')
        .attr('class', 'node')
        .attr('cx', (d, i) => this.xScale(i))
        .attr('cy', (d) => this.yScale(d.y))
        .attr('r', 5)
        .style('fill', '#69b3a2')
        .on('mouseover', (d, i, nodes) => {
          const node = d3.select(nodes[i])
          this.$emit('setSelectedSnapshot', i)
          this.setSelectedNode(d)
          node.transition()
            .duration(100)
            .attr('r', 6)
          // this.tooltip
          //   .style('opacity', 1)
          //   .style('left', `${d3.event.pageX - (this.totalWidth / 2) + 100}px`)
          //   .style('top', `${d3.event.pageY - (this.totalHeight / 2) - 70}px`)
        })
        .on('mouseout', (d, i, nodes) => {
          const node = d3.select(nodes[i])
          node.transition().duration(100).attr('r', 5)
          this.tooltip
            .style('opacity', 0)
          this.setSelectedNode(null)
        })
    }
  },
  computed: {
    formattedSnapshots() {
      return this.snapshots.map((snapshot) => ({
          ...snapshot,
          y: typeof snapshot.ticks === 'number' ? snapshot.ticks : 0
        }))
    },
    width() {
      return this.totalWidth - this.margin.left - this.margin.right
    },
    height() {
      return this.totalHeight - this.margin.top - this.margin.bottom
    },
    selectedNodeText() {
      if (!this.selectedNode) return ''
      return this.selectedNode.type
    },
    lineGenerator() {
      return d3.line()
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d.y))
        .curve(d3.curveMonotoneX)
    },
    xScale() {
      return d3.scaleLinear()
        .domain([0, this.snapshots.length])
        .range([this.margin.left, this.width])
    },
    yScale() {
      const maxY = Math.max(...this.formattedSnapshots.map((fs) => fs.y))
      return d3.scaleLinear()
        .domain([0, maxY])
        .range([this.height, this.margin.top])
    }
  },
  updated() {
    this.renderChart()
  },
  beforeUpdate() {
    d3.select('#player-graph')
      .selectAll('*')
      .remove()
  },
  mounted() {
    this.totalWidth = this.$refs.playerGraphMountNode.clientWidth
    this.totalHeight = this.$refs.playerGraphMountNode.clientHeight
    this.tooltip = d3.select('#player-graph-tooltip')
    this.renderChart()
  }
}
</script>

<style>
  .node {
    cursor: pointer;
  }
  #player-graph-ctr {
    border: 1px black solid;
    width: 100%;
    height: 100%;
  }
  #player-graph-mount-node {
    height: 100%;
    width: 100%;
  }
  #player-graph-tooltip {
    position: absolute;
    min-width: 50px;
    min-height: 40px;
    opacity: 0;
    border: 1px black solid;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: lightgrey;
  }
</style>
