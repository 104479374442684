import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class Signal extends ImageElement {
  get suffix() {
    if (!this.spec) return 'Inactive'
    return this.capitalize(this.spec)
  }

  get type() {
    return elementType.SIGNAL
  }

  get name() {
    return `${this.type}${this.suffix}`
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  get isDraggable() {
    if (this.isToolbarElement) return true
    return !!this.userPlaced
  }

  get isToggleable() {
    // return !this.isToolbarElement
    return false
  }

  get isLinkable() {
    return !this.isToolbarElement
  }

  get image() {
    if (!this.spec) return this.primaryImage
    // Signal is inactive by default in the toolbar
    return this.spec === 'active' ? this.primaryImage : this.secondaryImage
  }

  onToggle() {
    if (this.data.locked) return
    if (this.spec === 'active') {
      this.spec = 'inactive'
    } else {
      this.spec = 'active'
    }
  }

  create(data) {
    return new Signal(data)
  }
}
