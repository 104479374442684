<template>
  <div class='opm-view'>
    <div class='opm-header'>
      <div class="opm-controls">
        <v-select
          label='Select Level'
          :items='levels'
          :value='selectedLevel'
          @change='(lvl) => setSelectedLevel(lvl)'
          item-text="id"
          return-object
          class='opm-select'
        ></v-select>
        <!-- <v-select
          label='Select Play Session'
          v-model='selectedSnapshot'
          :items='logs'
          :item-text='(item) => new Date(item.created * 1000).toLocaleString()'
          :disabled='!logs.length'
          return-object
          class='opm-select'
        ></v-select> -->
      </div>
    </div>
    <div class='opm-content'>
      <div class='left-ctr'>
        <LevelView :selectedLevelString='selectedLevelString' :mapConfig="mapConfig"/>
        <div class='metrics-ctr'>
          <h3>Session Metrics</h3>
          <div><span class='snapshot-datum'>Number of Submissions:</span> {{snapshots.length}}</div>
          <div><span class='snapshot-datum'>Efficiency:</span> {{200}}</div>
          <div><span class='snapshot-datum'>Class Average:</span> {{30}}</div>
        </div>
      </div>
      <div id='right-ctr'>
        <div id='player-graph-ctr'>
          <v-progress-linear absolute class='progress-indicator' top v-if='loadingSnapshots' indeterminate color="yellow darken-2"></v-progress-linear>
          <div class='player-graph-inner'>
            <h3 class='session-data-title'>Session Graph</h3>
            <p v-if='!loadingSnapshots && !snapshots.length'>There is no data for this play session.</p>
          </div>
          <PlayerGraph v-if='snapshots.length' @setSelectedSnapshot='setSelectedSnapshot' :snapshots='snapshots'/>
        </div>
        <div class='snapshot-data-ctr'>
          <h3>Snapshot Data</h3>
          <div><span class='snapshot-datum'>Snapshot ID:</span> {{selectedSnapshot.id}}</div>
          <div><span class='snapshot-datum'>Submission Result:</span>  {{selectedSnapshot.submission_result}}</div>
          <div><span class='snapshot-datum'>Knowledge Statement:</span>  {{selectedSnapshot.knowledge_statement}}</div>
          <div><span class='snapshot-datum'>Moving Connected Elements:</span>  {{selectedSnapshot.moving_connected_elements}}</div>
          <div><span class='snapshot-datum'>Same Zone Linking:</span>  {{selectedSnapshot.same_zone_linking}}</div>
        </div>
        <div id='suggestions-ctr'>
          <v-progress-linear absolute class='progress-indicator' v-if='loadingSnapshots' indeterminate color="yellow darken-2"></v-progress-linear>
          <div class="suggestions-ctr-inner">
            <h3>Suggestions</h3>
            <ul v-if='selectedSnapshot.suggestions.length'>
              <li v-for='s in selectedSnapshot.suggestions' :key='s'>{{s}}</li>
            </ul>
            <p v-else>There are no suggestions for this snapshot. Hover the nodes above to see content.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LevelView from '@/components/level/LevelView.vue'
import PlayerGraph from '@/components/opm/PlayerGraph.vue'
import aws from '@/auth/aws'
import { elementType } from '@/vars'
import Link from '@/elements/Link'

export default {
  name: 'OPMView',
  components: {
    LevelView,
    PlayerGraph
  },
  data() {
    return {
      mapConfig: {
        isDisabled: true,
        iconSize: 100,
        width: 600,
        height: 600
      },
      selectedSnapshot: {
        suggestions: []
      },
      snapshots: [],
      loadingSnapshots: false,
    }
  },
  methods: {
    ...mapActions(['setSelectedLevel', 'showMessage', 'addElement', 'createLoadImageElements', 'draw', 'removeElement']),
    async init() {
      try {
        this.loadingSnapshots = true
        // TODO: implement actual server call and remove simulated wait
        await this.sleep(500)
        const data = await aws.getLog(this.selectedLevelString)
        if (data.length) {
          this.snapshots = data
          await this.setSelectedSnapshot(0)
        } else {
          this.showMessage(`No play session found. Please play level ${this.selectedLevelString} and return to this screen.`)
        }
      } catch (e) {
        this.showMessage(e)
      } finally {
        this.loadingSnapshots = false
      }
    },
    async setSelectedSnapshot(index) {
      this.selectedSnapshot = this.snapshots[index]
      Promise.all(this.userPlacedElements.map(async (el) => this.removeElement(el)))
      Promise.all(this.linkElements.map(async (el) => this.removeElement(el)))
      const boardState = this.selectedSnapshot.absolute_board_state
      if (boardState) {
        const components = []
        for (const id of Object.keys(boardState)) {
          const values = boardState[id]
          components.push({
            id,
            scale: this.scale.board,
            transform: this.transform,
            type: values.type,
            cell: [values.element_x, values.element_y],
            userPlaced: true,
            // TODO: add spec properly
            spec: 'active'
          })
        }
        await this.createLoadImageElements({ elementData: components, isBoardElement: true })
          for (const id of Object.keys(boardState)) {
            const values = boardState[id]
            const linkId = values.link
            if (linkId) {
              const sourceEl = this.selectByID(linkId)
              const targetEl = this.selectByID(id)
              if (sourceEl.id && targetEl.id) {
                const linkData = {
                  id: 'abc123',
                  sourceEl,
                  targetEl,
                  isBoardElement: false,
                  isLinkElement: true,
                  scale: this.scale.board,
                  type: elementType.LINK,
                  spec: 'active',
                  transform: this.transform
                }
                this.addElement(new Link(linkData))
              }
            }
          }
        this.draw()
      }
    }
  },
  computed: {
    ...mapGetters(['selectedLevelString', 'levels', 'selectedLevel', 'components', 'transform', 'scale', 'userPlacedElements', 'selectByID', 'linkElements']),
  },
  beforeRouteEnter: (to, from, next) => {
    next(async (vm) => {
      const isAuthenticated = await vm.$api.user.isAuthenticated()
      if (!isAuthenticated) {
        vm.$router.push({ name: 'LevelSelect' })
        setTimeout(() => {
          vm.showMessage('You cannot access that page.')
        }, 200)
      }
      return true
    })
  },
  async mounted() {
    await this.init()
  },
}
</script>

<style scoped>
  h3 {
    text-decoration: underline;
  }
  .opm-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 2rem;
  }
  #left-ctr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #right-ctr {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 2rem;
  }
  .snapshot-data-ctr {
    padding: 0.5rem;
    border: 1px solid black;
    margin-top: 1rem;
    height: 168px;
  }
  .opm-header {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .metrics-ctr {
    padding: 0.5rem;
    border: 1px solid black;
    width: 100%;
    margin-top: 1rem;
  }
  .opm-controls {
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    border: 1px black solid;
    width: 600px;
    padding: 0.5rem;
    padding-bottom: 0;
  }
  .snapshot-datum {
    font-weight: 700;
  }
  .opm-controls div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .opm-select {
    width: 60px;
  }
  .suggestions-ctr-inner {
    padding: 0.5rem;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .player-graph-inner {
    padding: 0.5rem;
    pointer-events: none;
    position: absolute;
  }
  .opm-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  #player-graph-ctr {
    border: 1px black solid;
    width: 100%;
    height: 400px;
    position: relative;
  }
  #suggestions-ctr {
    border: 1px black solid;
    margin-top: 1rem;
    height: 200px;
    position: relative;
  }
</style>
