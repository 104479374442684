import config from '@/config.json'
import has from 'lodash/has'

export default class APIService {
  get storage() {
    const defaultStorageType = 'session'
    const getStorageByType = (type) => (type === defaultStorageType ? window.sessionStorage : window.localStorage)
    const getKey = (key) => `${this.prefix.app}${key}`
    return {
      setItem: (key, value = '', storageType = defaultStorageType) => {
        getStorageByType(storageType).setItem(getKey(key), JSON.stringify(value))
      },
      getItem: (key, storageType = defaultStorageType) => {
        const value = getStorageByType(storageType).getItem(getKey(key))
        return value ? JSON.parse(value) : null
      },
      removeItem: (key, storageType = defaultStorageType) => {
        getStorageByType(storageType).removeItem(getKey(key))
      }
    }
  }

  get prefix() {
    return {
      cognito: `CognitoIdentityServiceProvider.${config.aws.appID}`,
      app: 'Parallel'
    }
  }

  get suffix() {
    return {
      cognito: 'LastAuthUser'
    }
  }

  // This deals with records in cognito
  get user() {
    return {
      getEmail: () => {
        const key = `${this.prefix.cognito}.${this.suffix.cognito}`
        return localStorage.getItem(key)
      },
      isAuthenticated: () => !!this.user.getEmail(),
      getAuthStatusString: () => (this.user.isAuthenticated() ? 'User is authenticated.' : 'User is not authenticated.'),
    }
  }

  // This deals with records in dynamodb player table (distinct from users)
  get player() {
    return {
      canAccessLevel: (levelId) => {
        const activity = this.storage.getItem('Activity')
        if (!activity || !activity.available_levels) return false
        const levelName = config.levelIdToNameMap[levelId]
        return activity.available_levels.includes(levelName)
      },
      // Combine these two functions and use key
      // Make into own api section?
      getCurrentActivityValueByKey: (key) => {
        const activity = this.storage.getItem('Activity')
        if (activity && has(activity, key)) {
          return activity[key]
        }
        return null
      },
      clearSessionData: async () => {
        this.storage.removeItem('Activity')
        this.storage.removeItem('Player')
        this.storage.removeItem('LogState')
      },
    }
  }
}
