<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingDialog',
  props: {
    color: {
      default: 'grey darken-4',
      type: String
    }
  },
  computed: {
    ...mapGetters(['isAppLoading']),
  }
}
</script>

<template>
  <v-overlay
    :value="isAppLoading"
    :color="color"
    class="loading-overlay"
  >
  <v-progress-circular
    indeterminate
    :size="250"
    :width="10"
    class="loading-animation"
  ></v-progress-circular>
  </v-overlay>
</template>

<style scoped>

.loading-animation {
  color: var(--primary);
}

.loading-overlay {
  margin-top: 48px;
  animation: fade 0.75s alternate;
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
}
</style>
