import ElementModifier from './ElementModifier'

export default class TrackModifier extends ElementModifier {
  get color() {
    return 'black'
    // return this.data.color
  }

  get coordinates() {
    const coordinates = [0, 0]
    return coordinates
  }

  get path() {
    switch (this.data.direction) {
      case 1: return 'M 20.00,12.00 C 20.00,12.00 17.17,14.83 17.17,14.83 17.17,14.83 26.34,24.00 26.34,24.00 26.34,24.00 17.17,33.17 17.17,33.17 17.17,33.17 20.00,36.00 20.00,36.00 20.00,36.00 32.00,24.00 32.00,24.00 32.00,24.00 20.00,12.00 20.00,12.00 Z' // east
      case 2: return 'M 36.58,19.41 C 36.58,19.41 33.75,16.58 33.75,16.58 33.75,16.58 24.59,25.75 24.59,25.75 24.59,25.75 15.41,16.59 15.41,16.59 15.41,16.59 12.58,19.42 12.58,19.42 12.58,19.42 24.59,31.41 24.59,31.41 24.59,31.41 36.58,19.41 36.58,19.41 Z' // south
      case 3: return 'M 29.17,34.92 C 29.17,34.92 32.00,32.09 32.00,32.09 32.00,32.09 22.83,22.92 22.83,22.92 22.83,22.92 32.00,13.75 32.00,13.75 32.00,13.75 29.17,10.92 29.17,10.92 29.17,10.92 17.17,22.92 17.17,22.92 17.17,22.92 29.17,34.92 29.17,34.92 Z' // west
      case 4: return 'M 12.59,27.50 C 12.59,27.50 15.42,30.33 15.42,30.33 15.42,30.33 24.58,21.16 24.58,21.16 24.58,21.16 33.76,30.33 33.76,30.33 33.76,30.33 36.59,27.50 36.59,27.50 36.59,27.50 24.58,15.50 24.58,15.50 24.58,15.50 12.59,27.50 12.59,27.50 Z' // north
      default: return ''
    }
  }
}
