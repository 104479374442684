/* eslint-disable no-unused-vars */
import ElementModifier from './ElementModifier'

export default class SwitchModifier extends ElementModifier {
  get color() {
    return this.status === 'active' ? 'grey' : 'lightgrey'
  }

  get coordinates() {
    // TODO: make this better
    const center = 10 * this.scale
    const lgOffset = 6
    const smOffset = center / 2
    const coordinates = [
      [center + 0.7, center - smOffset - 0.1], // Right
      [center - smOffset + 0.1, center + 0.7], // Bottom
      [center - lgOffset, center - smOffset], // Left
      [center - smOffset, center - lgOffset], // Top
    ]
    return coordinates[this.orientation - 1]
  }

  get scale() {
    return 0.75
  }

  get path() {
    const paths = [
      'M23.8,23.8L0,0v47.6L23.8,23.8z', // Right
      'M0,0h47.6L23.8,23.8L0,0z', // Bottom
      'M0,23.8l23.8,23.8V0L0,23.8z', // Left
      'M23.8,0L0,23.8h47.6L23.8,0z' // Top
    ]
    return paths[this.orientation - 1]
  }
}
