<template>
  <div class="auth-background">
    <v-card class="auth-ctr">
      <LogoCard/>
      <p>Enter the verification code below</p>
      <v-form ref="changePasswordForm" id="change-password-form" v-model='isValid' @submit.prevent='changePasswordAction'>
        <v-text-field
          v-model='code'
          label='Verification Code'
          :rules='[this.formRules.generic]'
          ref='verificationCode'
          required
        ></v-text-field>
        <v-text-field
          v-model='email'
          label='Email'
          :rules="[formRules.email]"
          ref='email'
          required
        ></v-text-field>
        <PasswordField
          v-model='primaryPassword'
          :show='show'
          label='Enter New Password'
          @toggle-show='toggleShow'
        />
        <PasswordField
          v-model='secondaryPassword'
          type='secondary'
          :show='show'
          label='Re-enter New Password'
          @toggle-show='toggleShow'
        />
        <v-btn
          type='submit'
          form='change-password-form'
          color='primary'
          class='change-password-btn'
          :disabled='!isValid'
        >Change Password</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import aws from '@/auth/aws'
import PasswordField from '@/components/auth/PasswordField.vue'
import LogoCard from '@/components/LogoCard.vue'
import { routeNames } from '../../vars'

export default {
  name: 'ChangePassword',
  components: {
    PasswordField,
    LogoCard
  },
  data() {
    return {
      code: '',
      email: '',
      primaryPassword: '',
      secondaryPassword: '',
      show: false,
      isValid: false
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    toggleShow() {
      this.show = !this.show
    },
    changePasswordAction() {
      this.enableLoadingDialog()
      aws.finishResetPassword(this.email, this.primaryPassword, this.code)
        .then(() => {
          this.disableLoadingDialog()
          const message = 'Password reset successful. Please log in using the new password.'
          this.showMessage(message)
          this.rtr.push({ name: routeNames.LOGIN })
        })
        .catch((error) => {
          this.showMessage(error.message)
          this.$refs.changePasswordForm.reset()
        })
        .finally(() => this.disableLoadingDialog())
    }
  }
}
</script>

<style scoped>
  #change-password-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .change-password-btn {
    margin-top: 1rem;
  }
</style>
