import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class LinksIndicator extends ImageElement {
  get suffix() {
    if (!this.spec) return 'Inactive'
    return this.capitalize(this.spec)
  }

  get type() {
    return elementType.LINKS_INDICATOR
  }

  get name() {
    return `${this.type}${this.suffix}`
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  get isActionable() {
    return true
  }

  get isClickable() {
    return true
  }

  get isDraggable() {
    return false
  }

  get isToggleable() {
    return true
  }

  get isLinkable() {
    return false
  }

  get image() {
    if (!this.spec) return this.primaryImage
    // Signal is inactive by default in the toolbar
    return this.spec === 'active' ? this.primaryImage : this.secondaryImage
  }

  onMouseDown() {
    this.onToggle()
  }

  async onToggle() {
    const res = await this.data.onMouseDownAction()
    if (!res) return
    if (this.spec === 'active') {
      this.spec = 'inactive'
    } else {
      this.spec = 'active'
    }
  }
}
