import ElementModifier from './ElementModifier'

export default class ElementHighlighter extends ElementModifier {
  get coordinates() {
    return [1, 0]
  }

  get path() {
    const path = 'M0,0H22.56V22.56H0Z'
    return path
  }
}
