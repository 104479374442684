/* eslint-disable no-shadow */
import Vue from 'vue'

const DEFAULT_SELECTED_LEVEL = '15'

const getDefaultState = () => ({
  selectedLevel: {
    id: DEFAULT_SELECTED_LEVEL,
    isAccessible: true
  },
  levels: [
    {
      id: '1',
      isAccessible: false,
    },
    {
      id: '2',
      isAccessible: false,
    },
    {
      id: '3',
      isAccessible: false,
    },
    {
      id: '4',
      isAccessible: false,
    },
    {
      id: '5',
      isAccessible: false,
    },
    {
      id: '6',
      isAccessible: false,
    },
    {
      id: '7',
      isAccessible: false,
    },
    {
      id: '8',
      isAccessible: false,
    },
    {
      id: '9',
      isAccessible: false,
    },
    {
      id: '10',
      isAccessible: false,
    },
    {
      id: '11',
      isAccessible: false,
    },
    {
      id: '12',
      isAccessible: false,
    },
    {
      id: '13',
      isAccessible: false,
    },
    {
      id: '14',
      isAccessible: false,
    },
    {
      id: '15',
      isAccessible: false,
    },
    {
      id: '16',
      isAccessible: false,
    },
    {
      id: '17',
      isAccessible: false,
    },
    {
      id: '18',
      isAccessible: false,
    },
    {
      id: '19',
      isAccessible: false,
    },
    {
      id: '20',
      isAccessible: false,
    },
    {
      id: '21',
      isAccessible: false,
    },
    {
      id: '22',
      isAccessible: false,
    },
    {
      id: '23',
      isAccessible: false,
    },
    {
      id: '24',
      isAccessible: false,
    }
  ]
})

const state = getDefaultState()

const getters = {
  levels: (state) => state.levels,
  selectedLevel: (state) => state.selectedLevel,
  selectedLevelString: (state) => state.selectedLevel.id.toString()
}

const actions = {
  setSelectedLevel: ({ commit }, payload) => {
    commit('setSelectedLevel', payload)
  },
  initLevels: ({ commit, getters }) => {
    const levels = getters.levels.map((l) => ({
      ...l,
      isAccessible: true,
      // TODO: if using study association for players, then check if they can access level
      // isAccessible: this.$api.player.canAccessLevel(l.id)
    }))
    commit('setLevels', levels)
  }
}

const mutations = {
  setSelectedLevel: (state, payload) => {
    Vue.set(state, 'selectedLevel', payload)
  },
  setLevels: (state, payload) => {
    Vue.set(state, 'levels', payload)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
