import Mutation from './Mutation'

export default class MoveToCell extends Mutation {
  constructor(data) {
    super(data)
    this.sCell = data.element.cell
    this.tCell = data.value
  }

  // Source cell, i.e. starting cell
  get sCell() {
    return this.data.sCell
  }

  set sCell(cell) {
    this.data.sCell = cell
  }

  // Target cell, i.e. destination cell
  get tCell() {
    return this.data.tCell
  }

  set tCell(cell) {
    this.data.tCell = cell
  }

  mutate(elapsed) {
    if (!this.sCell.length) {
      this.sCell = this.tCell
    }
    const t = Math.min(1, (elapsed / this.duration))
    // For all mutations, make minor mutations
    const x = this.sCell[0] * (1 - t) + this.tCell[0] * t
    const y = this.sCell[1] * (1 - t) + this.tCell[1] * t
    this._mutate([x, y])
  }
}
