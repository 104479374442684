// import Action from './Action'

export default class SimulationStep {
  constructor(data) {
    this._data = data
  }

  get data() {
    return this._data
  }

  get mutations() {
    return this.data.mutations
  }

  get component() {
    return this.data.component
  }
}
