<template>
<span>
  <h3 class="c10" id="h.z4vzbyentu2u"><span class="c9">PARALLEL PRIVACY AND DATA POLICY</span></h3>
  <p class="c7"><span>ISSUED BY Procedural eXpression Lab (PXL) at Drexel University and Games User Interaction and
      Intelligence Lab, University of California, Santa Cruz.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">Last Updated: 4/26/2021</span></p>
  <p class="c2"><span class="c8"></span></p>
  <h4 class="c3" id="h.6b0ecbqrzxsh"><span class="c5">INTRODUCTION AND GENERAL TERMS</span></h4>
  <p class="c7"><span class="c0">These terms apply to the use of the game PARALLEL. The game website is created by the
      Procedural eXpression Lab (PXL) at Drexel University and the Games User Interaction and Intelligence (GUII) Lab at
      the University of California, Santa Cruz. </span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">This privacy and data policy (&ldquo;Privacy Policy&rdquo;) applies and has effect on
      the game PARALLEL, as well as other survey and questionnaire features that relate to the game. If you have any
      questions or comments about this Privacy Policy, please contact us at parallelsupp@gmail.com</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">We are committed to protecting and respecting your privacy. The Privacy Policy explains
      the basis on which personal information we collect from you will be processed by us. Where we decide the purpose
      or means for which personal data you supply through this game is processed, we are the &ldquo;controller&rdquo;.
      We will comply with all applicable data protection laws, including the General Data Protection Regulation 2016/679
      and California Consumer Privacy Act 2018 (&ldquo;CCPA&rdquo;).</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">Please read this Privacy Policy carefully as it contains important information about
      the following:</span></p>
  <p class="c2"><span class="c0"></span></p>
  <ul class="c4 lst-kix_hvz6aie9hwh6-0 start">
    <li class="c1 li-bullet-0"><span class="c0">What information we may collect about you;</span></li>
    <li class="c1 li-bullet-0"><span class="c0">How we will use information we collect about you;</span></li>
    <li class="c1 li-bullet-0"><span class="c0">Whether we will disclose your details to anyone else; and</span></li>
    <li class="c1 li-bullet-0"><span class="c0">Your choices and rights regarding the personal information you have
        provided to us.</span></li>
  </ul>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">We may make changes to this Privacy Policy in future, which will be posted on this
      page. You should check this page from time to time to ensure you are aware of any changes. Where appropriate we
      may notify you of changes through the game.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.xnuk0cuhc0zm"><span class="c5">INFORMATION WE MAY COLLECT ABOUT YOU</span></h4>
  <p class="c7"><span class="c0">We collect and process the following information provided by you when using the game
      PARALLEL.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">We will collect the following information from you when you play PARALLEL:</span></p>
  <p class="c7"><span class="c0">(&ldquo;Basic Information&rdquo;)</span></p>
  <ul class="c4 lst-kix_wyywbkhhuflw-0 start">
    <li class="c1 li-bullet-0"><span>your email address;</span></li>
  </ul>
  <p class="c2"><span class="c8"></span></p>
  <p class="c7"><span class="c0">(&ldquo;Gameplay Information&rdquo;)</span></p>
  <ul class="c4 lst-kix_dr7vtntrmq4a-0 start">
    <li class="c1 li-bullet-0"><span class="c0">game play attempts, actions taken, progression, and results</span></li>
    <li class="c1 li-bullet-0"><span class="c0">session game time start, end and duration</span></li>
  </ul>
  <p class="c2"><span class="c8"></span></p>
  <h4 class="c3" id="h.km6r34pux829"><span class="c5">COOKIES</span></h4>
  <p class="c7"><span class="c0">The collection of this information may include the use of cookies and similar
      technologies (for example, which are used on the Website). A cookie is a string of information that a website
      stores on a visitor&rsquo;s computer, and that the visitor&rsquo;s browser provides to the website each time the
      visitor returns. Cookies are widely used in order to make websites work, to provide functionality, to make them
      more efficient and to provide website owners with information about how the website is being used and who&#39;s
      using it. We use a limited number of cookies on our website.</span></p>
  <p class="c2"><span class="c14"></span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.borvhw55a3d8"><span class="c12">WHY WE COLLECT INFORMATION ABOUT YOU</span></h4>
  <ol class="c4 lst-kix_3sburpqlaadc-0 start" start="1">
    <li class="c1 li-bullet-0"><span class="c0">To provide our game PARALLEL to you</span></li>
  </ol>
  <ol class="c4 lst-kix_3sburpqlaadc-1 start" start="1">
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">We will use information about you for delivering our game
        PARALLEL to you. The processing of information in this way is necessary for us to record your progress and
        current status within PARALLEL, and to ensure the game delivers the features promised and function properly, so
        that you have the best experience when playing PARALLEL.</span></li>
  </ol>
  <p class="c2 c11"><span class="c0"></span></p>
  <ol class="c4 lst-kix_3sburpqlaadc-0" start="2">
    <li class="c1 li-bullet-0"><span class="c0">To help us improve the Online Services and fix any problems</span></li>
  </ol>
  <ol class="c4 lst-kix_3sburpqlaadc-1 start" start="1">
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">We may process information about you (including Basic
        Information, User Content and Analytics) so that we can analyse and improve the game PARALLEL. This processing
        is also necessary for us to (i) ensure that our game functions properly so that you and other users have the
        best experience when playing PARALLEL, (ii) identifying and correcting any bugs in the game.</span></li>
  </ol>
  <p class="c2 c11"><span class="c0"></span></p>
  <ol class="c4 lst-kix_3sburpqlaadc-0" start="3">
    <li class="c1 li-bullet-0"><span class="c0">To respond to your inquiries and requests for support</span></li>
  </ol>
  <ol class="c4 lst-kix_3sburpqlaadc-1 start" start="1">
    <li class="c7 c13 c11 li-bullet-0"><span>We may process Contact Information so that we are able to properly respond
        to your enquiries and support requests from parallelsupp@gmail.com</span></li>
  </ol>
  <p class="c2 c11"><span class="c8"></span></p>
  <ol class="c4 lst-kix_3sburpqlaadc-0" start="4">
    <li class="c1 li-bullet-0"><span class="c0">To research user behavior within the game environment</span></li>
  </ol>
  <ol class="c4 lst-kix_3sburpqlaadc-1 start" start="1">
    <li class="c7 c11 c13 li-bullet-0"><span class="c0">We will process gameplay data in order to study user behavior
        within the environment. Gameplay data will be anonymized and will not include any identifiers. There will be no
        way of identifying you from your data.</span></li>
  </ol>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.ye3q909ubakq"><span class="c5">CONSUMER CONTROL AND OPT-OUT OPTIONS</span></h4>
  <p class="c7"><span>If you decide that you do not wish to be part of a user study or have your data be used you can
      opt-out by contacting </span><span class="c18"><a class="c16"
        href="mailto:parallelsupp@gmail.com">parallelsupp@gmail.com</a></span><span class="c0">. We will correct or
      delete personal information held about you on our files.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.v159a3vu9f95"><span class="c5">YOUR RIGHTS IN RELATION TO PERSONAL DATA WHICH WE PROCESS RELATING
      TO YOU</span></h4>
  <p class="c7"><span class="c0">You have the following rights over the way we process personal data relating to you, as
      set out below. We aim to comply without undue delay, and within one month at the latest.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span>To make a request, please let us know by sending an email to parallelsupp@gmail.com</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span>You have the right to:</span></p>
  <p class="c2"><span class="c0"></span></p>
  <ol class="c4 lst-kix_gd3fgaqmv9jb-0 start" start="1">
    <li class="c1 li-bullet-0"><span class="c0">Object to us processing data about you.You can ask us to restrict, stop
        processing, or to delete your personal data if:</span></li>
  </ol>
  <ol class="c4 lst-kix_gd3fgaqmv9jb-1 start" start="1">
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">you consented to our processing the personal data, and have
        withdrawn that consent;</span></li>
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">we no longer need to process that personal data for the reason
        it was collected;</span></li>
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">you need the personal data to be deleted in order to comply with
        legal obligations;</span></li>
    <li class="c7 c13 c11 li-bullet-0"><span class="c0">the personal data is processed in relation to the offer of a
        service to a child.</span></li>
  </ol>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.6fkqqynzizm4"><span class="c5">MINORS</span></h4>
  <p class="c7"><span>We do not solicit information from or market our game to persons under the age of 18. In the event
      that we learn that we have collected personal information from a person under 18 years of age we will delete that
      information as quickly as possible. If you believe that we might have any information from or about a person under
      18 years of age please contact us at parallelsupp@gmail.com</span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.s37p3ourqe7a"><span class="c5">SECURITY</span></h4>
  <p class="c7"><span class="c0">We will take all reasonable technical and organisational precautions to prevent the
      loss, misuse or alteration of your personal information. For example, our databases are password protected and
      limited to essential employees only (such as FTG management or employees whose main role requires system
      access).</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">Please be aware that, although we endeavour to provide reasonable security for
      information we process and maintain, no security system can prevent all potential security breaches.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.sq61bce4ozea"><span class="c5">CALIFORNIA RESIDENTS &amp; CCPA</span></h4>
  <p class="c7"><span class="c0">If you are a California resident, as defined in Section 17014 of Title 18 of the
      California Code of Regulations, you have certain rights in respect of the personal information we hold about
      you.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <p class="c7"><span class="c0">If you have questions or concerns about our privacy policies and practices, please
      contact us at the details set out at the end of this Policy.</span></p>
  <p class="c2"><span class="c0"></span></p>
  <h4 class="c3" id="h.nf8il9mqvzql"><span class="c5">CONTACT INFORMATION</span></h4>
  <p class="c7"><span class="c0">All questions, comments or enquiries should be directed to parallelsupp@gmail.com We
      will endeavour to respond to any query or questions within three business days.</span></p>
  </span>
</template>

<script>
export default {
  name: 'AgreementContent'
}
</script>

<style scoped>
    .lst-kix_gd3fgaqmv9jb-8>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-8
    }

    ol.lst-kix_3sburpqlaadc-7.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-7 0
    }

    .lst-kix_3sburpqlaadc-8>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-8
    }

    ol.lst-kix_3sburpqlaadc-3 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-2 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-2.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-2 0
    }

    ol.lst-kix_3sburpqlaadc-1 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-0 {
      list-style-type: none
    }

    .lst-kix_3sburpqlaadc-7>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-7
    }

    ol.lst-kix_3sburpqlaadc-8 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-7 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-6 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-5 {
      list-style-type: none
    }

    ol.lst-kix_3sburpqlaadc-4 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-5.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-5 0
    }

    ol.lst-kix_3sburpqlaadc-2.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-2 0
    }

    .lst-kix_wyywbkhhuflw-0>li:before {
      content: "\0025cf  "
    }

    .lst-kix_wyywbkhhuflw-1>li:before {
      content: "\0025cb  "
    }

    .lst-kix_3sburpqlaadc-5>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-5
    }

    .lst-kix_gd3fgaqmv9jb-1>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-1
    }

    .lst-kix_gd3fgaqmv9jb-4>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-4
    }

    .lst-kix_wyywbkhhuflw-8>li:before {
      content: "\0025a0  "
    }

    ol.lst-kix_3sburpqlaadc-1.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-1 0
    }

    .lst-kix_gd3fgaqmv9jb-7>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-7
    }

    .lst-kix_wyywbkhhuflw-7>li:before {
      content: "\0025cb  "
    }

    ol.lst-kix_gd3fgaqmv9jb-4.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-4 0
    }

    .lst-kix_hvz6aie9hwh6-7>li:before {
      content: "\0025cb  "
    }

    ul.lst-kix_hvz6aie9hwh6-8 {
      list-style-type: none
    }

    .lst-kix_wyywbkhhuflw-4>li:before {
      content: "\0025cb  "
    }

    .lst-kix_wyywbkhhuflw-5>li:before {
      content: "\0025a0  "
    }

    ul.lst-kix_wyywbkhhuflw-8 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-6 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-7 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-6 {
      list-style-type: none
    }

    .lst-kix_wyywbkhhuflw-2>li:before {
      content: "\0025a0  "
    }

    .lst-kix_wyywbkhhuflw-6>li:before {
      content: "\0025cf  "
    }

    ul.lst-kix_wyywbkhhuflw-7 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-4 {
      list-style-type: none
    }

    .lst-kix_hvz6aie9hwh6-4>li:before {
      content: "\0025cb  "
    }

    .lst-kix_hvz6aie9hwh6-8>li:before {
      content: "\0025a0  "
    }

    ul.lst-kix_wyywbkhhuflw-5 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-2 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-0 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-3 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-1 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-0 {
      list-style-type: none
    }

    ul.lst-kix_wyywbkhhuflw-1 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-4 {
      list-style-type: none
    }

    .lst-kix_hvz6aie9hwh6-5>li:before {
      content: "\0025a0  "
    }

    .lst-kix_wyywbkhhuflw-3>li:before {
      content: "\0025cf  "
    }

    ul.lst-kix_hvz6aie9hwh6-5 {
      list-style-type: none
    }

    ul.lst-kix_hvz6aie9hwh6-2 {
      list-style-type: none
    }

    .lst-kix_hvz6aie9hwh6-6>li:before {
      content: "\0025cf  "
    }

    ul.lst-kix_hvz6aie9hwh6-3 {
      list-style-type: none
    }

    .lst-kix_gd3fgaqmv9jb-0>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-0, decimal) ". "
    }

    .lst-kix_gd3fgaqmv9jb-2>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-2
    }

    .lst-kix_3sburpqlaadc-3>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-3
    }

    ol.lst-kix_gd3fgaqmv9jb-3.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-3 0
    }

    .lst-kix_hvz6aie9hwh6-0>li:before {
      content: "\0025cf  "
    }

    .lst-kix_gd3fgaqmv9jb-1>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-1, lower-latin) ". "
    }

    .lst-kix_hvz6aie9hwh6-3>li:before {
      content: "\0025cf  "
    }

    .lst-kix_gd3fgaqmv9jb-2>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-2, lower-roman) ". "
    }

    .lst-kix_gd3fgaqmv9jb-4>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-4, lower-latin) ". "
    }

    .lst-kix_hvz6aie9hwh6-1>li:before {
      content: "\0025cb  "
    }

    .lst-kix_hvz6aie9hwh6-2>li:before {
      content: "\0025a0  "
    }

    .lst-kix_gd3fgaqmv9jb-3>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-3, decimal) ". "
    }

    .lst-kix_3sburpqlaadc-2>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-2
    }

    ol.lst-kix_3sburpqlaadc-0.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-0 0
    }

    ol.lst-kix_3sburpqlaadc-3.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-3 0
    }

    .lst-kix_gd3fgaqmv9jb-3>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-3
    }

    ol.lst-kix_gd3fgaqmv9jb-6.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-6 0
    }

    .lst-kix_dr7vtntrmq4a-6>li:before {
      content: "\0025cf  "
    }

    ol.lst-kix_3sburpqlaadc-8.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-8 0
    }

    ol.lst-kix_gd3fgaqmv9jb-8 {
      list-style-type: none
    }

    .lst-kix_dr7vtntrmq4a-5>li:before {
      content: "\0025a0  "
    }

    .lst-kix_dr7vtntrmq4a-7>li:before {
      content: "\0025cb  "
    }

    ol.lst-kix_gd3fgaqmv9jb-7 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-6 {
      list-style-type: none
    }

    .lst-kix_dr7vtntrmq4a-2>li:before {
      content: "\0025a0  "
    }

    ol.lst-kix_3sburpqlaadc-6.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-6 0
    }

    .lst-kix_dr7vtntrmq4a-3>li:before {
      content: "\0025cf  "
    }

    .lst-kix_3sburpqlaadc-4>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-4
    }

    .lst-kix_dr7vtntrmq4a-4>li:before {
      content: "\0025cb  "
    }

    .lst-kix_3sburpqlaadc-1>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-1
    }

    .lst-kix_gd3fgaqmv9jb-6>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-6, decimal) ". "
    }

    .lst-kix_gd3fgaqmv9jb-8>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-8, lower-roman) ". "
    }

    .lst-kix_gd3fgaqmv9jb-5>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-5, lower-roman) ". "
    }

    .lst-kix_dr7vtntrmq4a-8>li:before {
      content: "\0025a0  "
    }

    .lst-kix_gd3fgaqmv9jb-7>li:before {
      content: ""counter(lst-ctn-kix_gd3fgaqmv9jb-7, lower-latin) ". "
    }

    .lst-kix_3sburpqlaadc-0>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-0
    }

    ol.lst-kix_gd3fgaqmv9jb-8.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-8 0
    }

    .lst-kix_gd3fgaqmv9jb-5>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-5
    }

    .lst-kix_3sburpqlaadc-6>li {
      counter-increment: lst-ctn-kix_3sburpqlaadc-6
    }

    ol.lst-kix_gd3fgaqmv9jb-1.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-1 0
    }

    .lst-kix_3sburpqlaadc-4>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-4, lower-latin) ". "
    }

    .lst-kix_gd3fgaqmv9jb-0>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-0
    }

    ol.lst-kix_3sburpqlaadc-5.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-5 0
    }

    .lst-kix_3sburpqlaadc-3>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-3, decimal) ". "
    }

    .lst-kix_gd3fgaqmv9jb-6>li {
      counter-increment: lst-ctn-kix_gd3fgaqmv9jb-6
    }

    .lst-kix_3sburpqlaadc-1>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-1, lower-latin) ". "
    }

    ol.lst-kix_gd3fgaqmv9jb-1 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-0 {
      list-style-type: none
    }

    .lst-kix_3sburpqlaadc-0>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-0, decimal) ". "
    }

    .lst-kix_3sburpqlaadc-2>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-2, lower-roman) ". "
    }

    .lst-kix_dr7vtntrmq4a-1>li:before {
      content: "\0025cb  "
    }

    .lst-kix_dr7vtntrmq4a-0>li:before {
      content: "\0025cf  "
    }

    ol.lst-kix_gd3fgaqmv9jb-5 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-4 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-3 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-2 {
      list-style-type: none
    }

    ol.lst-kix_gd3fgaqmv9jb-7.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-7 0
    }

    ol.lst-kix_gd3fgaqmv9jb-0.start {
      counter-reset: lst-ctn-kix_gd3fgaqmv9jb-0 0
    }

    li.li-bullet-0:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt
    }

    .lst-kix_3sburpqlaadc-5>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-5, lower-roman) ". "
    }

    .lst-kix_3sburpqlaadc-6>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-6, decimal) ". "
    }

    ol.lst-kix_3sburpqlaadc-4.start {
      counter-reset: lst-ctn-kix_3sburpqlaadc-4 0
    }

    .lst-kix_3sburpqlaadc-7>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-7, lower-latin) ". "
    }

    ul.lst-kix_dr7vtntrmq4a-0 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-1 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-2 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-3 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-4 {
      list-style-type: none
    }

    .lst-kix_3sburpqlaadc-8>li:before {
      content: ""counter(lst-ctn-kix_3sburpqlaadc-8, lower-roman) ". "
    }

    ul.lst-kix_dr7vtntrmq4a-5 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-6 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-7 {
      list-style-type: none
    }

    ul.lst-kix_dr7vtntrmq4a-8 {
      list-style-type: none
    }

    ol {
      margin: 0;
      padding: 0
    }

    table td,
    table th {
      padding: 0
    }

    .c6 {
      -webkit-text-decoration-skip: none;
      color: #000000;
      font-weight: 400;
      text-decoration: line-through;
      vertical-align: baseline;
      text-decoration-skip-ink: none;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c14 {
      -webkit-text-decoration-skip: none;
      color: #ff0000;
      font-weight: 400;
      text-decoration: line-through;
      vertical-align: baseline;
      text-decoration-skip-ink: none;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c1 {
      margin-left: 36pt;
      padding-top: 0pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c15 {
      color: #666666;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Arial";
      font-style: normal
    }

    .c2 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
    }

    .c0 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c9 {
      color: #434343;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 14pt;
      font-family: "Arial";
      font-style: normal
    }

    .c10 {
      padding-top: 16pt;
      padding-bottom: 4pt;
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c5 {
      color: #666666;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Arial";
      font-style: normal
    }

    .c8 {
      color: #ff0000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c3 {
      padding-top: 14pt;
      padding-bottom: 4pt;
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c7 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c18 {
      text-decoration-skip-ink: none;
      -webkit-text-decoration-skip: none;
      color: #1155cc;
      text-decoration: underline
    }

    .c17 {
      background-color: #ffffff;
      max-width: 468pt;
      padding: 72pt 72pt 72pt 72pt
    }

    .c16 {
      color: inherit;
      text-decoration: inherit
    }

    .c4 {
      padding: 0;
      margin: 0
    }

    .c13 {
      padding-left: 0pt
    }

    .c11 {
      margin-left: 72pt
    }

    .c12 {
      font-weight: 700
    }

    .title {
      padding-top: 0pt;
      color: #000000;
      font-size: 26pt;
      padding-bottom: 3pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .subtitle {
      padding-top: 0pt;
      color: #666666;
      font-size: 15pt;
      padding-bottom: 16pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    li {
      color: #000000;
      font-size: 11pt;
      font-family: "Arial"
    }

    p {
      margin: 0;
      color: #000000;
      font-size: 11pt;
      font-family: "Arial"
    }

    h1 {
      padding-top: 20pt;
      color: #000000;
      font-size: 20pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h2 {
      padding-top: 18pt;
      color: #000000;
      font-size: 16pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h3 {
      padding-top: 16pt;
      color: #434343;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h4 {
      padding-top: 14pt;
      color: #666666;
      font-size: 12pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h5 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    h6 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
    }
  </style>
