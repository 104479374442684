import { v4 as uuidv4 } from 'uuid'
import has from 'lodash/has'

export const logEventCategories = {
  BOARD: 'BOARD',
  DEFAULT: 'DEFAULT',
  INTERFACE: 'INTERFACE',
  SYSTEM: 'SYSTEM',
}

export const logEventTypes = {
  ADD_ELEMENT: 'ADD_ELEMENT',
  BASE: 'BASE',
  BEGIN_LEVEL_LOAD: 'BEGIN_LEVEL_LOAD',
  BEGIN_LINK: 'BEGIN_LINK',
  BEGIN_SIMULATION: 'BEGIN_SIMULATION',
  BOARD_SNAPSHOT: 'BOARD_SNAPSHOT',
  FINISH_LEVEL_LOAD: 'FINISH_LEVEL_LOAD',
  FINISH_LINK: 'FINISH_LINK',
  FINISH_SIMULATION: 'FINISH_SIMULATION',
  FINISH_TUTORIAL: 'FINISH_TUTORIAL',
  HIGHLIGHT_TRACK: 'HIGHLIGHT_TRACK',
  INTERFACE: 'INTERFACE',
  LINK_ELEMENT: 'LINK_ELEMENT',
  MESSAGE: 'MESSAGE',
  MOVE_ELEMENT: 'MOVE_ELEMENT',
  MUTATE_ELEMENT: 'MUTATE_ELEMENT',
  PAUSE_SIMULATION: 'PAUSE_SIMULATION',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',
  REMOVE_LINK: 'REMOVE_LINK',
  SET_REFLECTION_CONTENT: 'SET_REFLECTION_CONTENT',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  SKIP_SIMULATION: 'SKIP_SIMULATION',
  STOP_SIMULATION: 'STOP_SIMULATION',
  SUBMIT: 'SUBMIT',
  SYSTEM: 'SYSTEM',
  TEST: 'TEST',
  TOGGLE_ELEMENT: 'TOGGLE_ELEMENT',
  TOGGLE_LINKS: 'TOGGLE_LINKS',
  TUTORIAL_STEP: 'TUTORIAL_STEP',
}

class BaseLogEvent {
  constructor(data = {}) {
    this._data = data
    this.category = data.category || logEventCategories.DEFAULT
    this.created = data.created || Number.parseInt(Date.now(), 10)
    this.element = has(data, 'element._data') ? data.element.serialize() : null
    this.id = data.id || uuidv4()
    this.type = data.type || logEventTypes.BASE
  }

  get category() {
    return this.data.category
  }

  set category(category) {
    this.data.category = category
  }

  get created() {
    return this.data.created
  }

  set created(created) {
    this.data.created = created
  }

  get data() {
    return this._data
  }

  set data(data) {
    this._data = data
  }

  get id() {
    return this.data.id
  }

  set id(id) {
    this.data.id = id
  }
}

// SYSTEM
class SystemLogEvent extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.category = logEventCategories.SYSTEM
  }
}

class LevelLoad extends SystemLogEvent {
  constructor(data) {
    super(data)
    this.name = data.name
  }

  get name() {
    return this.data.levelName
  }

  set name(name) {
    this.data.name = name
  }

  get order() {
    return this.data.order
  }

  set order(order) {
    this.data.order = order
  }
}

class BeginLevelLoad extends LevelLoad {
  constructor(data) {
    super(data)
    this.type = logEventTypes.BEGIN_LEVEL_LOAD
  }
}

class FinishLevelLoad extends SystemLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.FINISH_LEVEL_LOAD
  }
}

class SetReflectionContent extends SystemLogEvent {
  constructor(data) {
    super(data)
    this.content = data.content || {}
    this.type = logEventTypes.SET_REFLECTION_CONTENT
  }

  get content() {
    return this.data.content
  }

  set content(content) {
    this.data.content = content
  }
}

class RemoveLink extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.link = has(data, 'link._data') ? data.link.serialize() : null
  }

  get link() {
    return this.data.link
  }

  set link(link) {
    this.data.link = link
  }
}

class ElementLogEvent extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.element = has(data, 'element._data') ? data.element.serialize() : data.element
  }

  get element() {
    return this.data.element
  }

  set element(element) {
    this.data.element = element
  }
}

// BOARD
class BoardSnapshot extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.BOARD_SNAPSHOT
  }

  get board() {
    return this.data.board
  }

  set board(board) {
    this.data.board = JSON.parse(JSON.stringify(board))
  }
}

class AddElement extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.ADD_ELEMENT
  }
}

class AddRemoveElementLogEvent extends ElementLogEvent {
  get startCell() {
    return this.data.startCell
  }

  set startCell(startCell) {
    this.data.startCell = startCell
  }
}

class RemoveElement extends AddRemoveElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.REMOVE_ELEMENT
  }
}

class MoveElement extends AddRemoveElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.MOVE_ELEMENT
  }
}

class LinkElement extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.LINK_ELEMENT
  }
}

class ToggleElement extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.TOGGLE_ELEMENT
  }
}

class MutateElement extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.mutations = data.mutations || {}
    this.type = logEventTypes.MUTATE_ELEMENT
  }

  get mutations() {
    return this.data.mutations
  }

  set mutations(mutations) {
    this.data.mutations = mutations
  }
}

class BeginLink extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.BEGIN_LINK
  }
}

class FinishLink extends ElementLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.FINISH_LINK
  }
}

class TutorialStep extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.step = data.step || {}
    this.type = logEventTypes.TUTORIAL_STEP
  }
}

class FinishTutorial extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.FINISH_TUTORIAL
  }
}

// INTERFACE
class InterfaceLogEvent extends BaseLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventCategories.INTERFACE
  }
}

class SubmitAttempt extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.SUBMIT_ATTEMPT
  }
}

class TestAttempt extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.TEST_ATTEMPT
  }
}

class ShowMessage extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.SHOW_MESSAGE
    this.message = data.message || ''
  }
}

class HighlightTrack extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.value = data.value
    this.index = data.index
    this.type = logEventTypes.HIGHLIGHT_TRACK
  }

  get value() {
    return this.data.value
  }

  set value(value) {
    this.data.value = value
  }

  get index() {
    return this.data.index
  }

  set index(index) {
    this.data.index = index
  }
}

class SimulationLogEvent extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.current = data.current || null
    this.total = data.total || null
  }

  get current() {
    return this.data.currentStep
  }

  set current(current) {
    this.data.current = current
  }

  get total() {
    return this.data.total
  }

  set total(total) {
    this.data.total = total
  }
}

class BeginSimulation extends SimulationLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.BEGIN_SIMULATION
  }
}

class StopSimulation extends SimulationLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.STOP_SIMULATION
  }
}

class PauseSimulation extends SimulationLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.PAUSE_SIMULATION
  }
}

class SkipSimulation extends SimulationLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.SKIP_SIMULATION
  }
}

class FinishSimulation extends SimulationLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.FINISH_SIMULATION
  }
}

class ToggleLinks extends InterfaceLogEvent {
  constructor(data) {
    super(data)
    this.type = logEventTypes.TOGGLE_LINKS
  }
}

export const logEventClasses = {
  ADD_ELEMENT: AddElement,
  BASE: BaseLogEvent,
  BEGIN_LEVEL_LOAD: BeginLevelLoad,
  BEGIN_LINK: BeginLink,
  BEGIN_SIMULATION: BeginSimulation,
  BOARD_SNAPSHOT: BoardSnapshot,
  FINISH_LEVEL_LOAD: FinishLevelLoad,
  FINISH_LINK: FinishLink,
  FINISH_SIMULATION: FinishSimulation,
  FINISH_TUTORIAL: FinishTutorial,
  HIGHLIGHT_TRACK: HighlightTrack,
  INTERFACE: InterfaceLogEvent,
  LINK_ELEMENT: LinkElement,
  MOVE_ELEMENT: MoveElement,
  MUTATE_ELEMENT: MutateElement,
  PAUSE_SIMULATION: PauseSimulation,
  REMOVE_ELEMENT: RemoveElement,
  REMOVE_LINK: RemoveLink,
  SET_REFLECTION_CONTENT: SetReflectionContent,
  SHOW_MESSAGE: ShowMessage,
  SKIP_SIMULATION: SkipSimulation,
  STOP_SIMULATION: StopSimulation,
  SUBMIT: SubmitAttempt,
  SYSTEM: SystemLogEvent,
  TEST: TestAttempt,
  TOGGLE_ELEMENT: ToggleElement,
  TOGGLE_LINKS: ToggleLinks,
  TUTORIAL_STEP: TutorialStep,
}
