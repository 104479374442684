import { elementType } from '../vars'

/**
 *    Script:         goals.js
 *    Description:    This script has functions used for tasks
 *                    related to level goals, for use by the
 *                    solution checker
 *
 *    Author:         Boyd Fox                    Date: 6/15/2020
 *    Notes:
 */

/**
 * enums for the comparison strings in the board format
 */
export const conditions = {
  EQUAL: 'eq',
  NOTEQUAL: 'ne',
  LESSTHAN: 'lt',
  GREATERTHAN: 'gt',
}

/**
 * addFailures() adds various aditional goals to
 * the goals struct so that we can determine
 * certain failure cases without needing a new system
 */
export function addFailures(threads, goals, maxStarvation) {
  // missed deliveries
  for (let index = 0; index < threads.length; index += 1) {
    goals.push({
      condition: 'lt',
      property: 'missed',
      id: threads[index],
      type: elementType.THREAD,
      value: 1,
      systemAdded: true
    })
    goals.push({
      condition: 'lt',
      property: 'deadend',
      id: threads[index],
      type: elementType.THREAD,
      value: 1,
      systemAdded: true
    })
    goals.push({
      condition: 'lt',
      property: 'starvation',
      id: threads[index],
      type: elementType.THREAD,
      value: maxStarvation,
      systemAdded: true
    })
  }
}

/**
 * failureCheck() returns a boolen value indicating if
 * any goals have reached a state from which they can
 * no longer be complete (ie. too many packages being delivered)
 */
export function failureCheck(board, goals, threads, failure) {
  if (failure.failure) {
    return true
  }

  for (let index = 0; index < goals.length; index += 1) {
    switch (goals[index].condition) {
      case conditions.EQUAL:
        if (board.components[goals[index].id.toString()][goals[index].property]
          > goals[index].value) {
          return true
        }

        break
      case conditions.LESSTHAN:
        if (board.components[goals[index].id.toString()][goals[index].property]
          >= goals[index].value) {
          return true
        }

        break
    }
  }

  return false
}

/**
 * getGoalStatus() returns a boolean based on
 * whether the given goal has had its conditions
 * met based on the board argument
 */
export function getGoalStatus(board, goal) {
  const property = board.components[goal.id.toString()][goal.property]
  switch (goal.condition) {
    case conditions.EQUAL:
      if (Array.isArray(property)) {
        if (JSON.stringify(property) !== JSON.stringify(goal.value)) {
          return false
        }
      } else if (property !== goal.value) {
        return false
      }
      break

    case conditions.NOTEQUAL:
      if (Array.isArray(property)) {
        if (JSON.stringify(property) === JSON.stringify(goal.value)) {
          return false
        }
      } else if (property === goal.value) {
        return false
      }
      break

    case conditions.LESSTHAN:
      if (property >= goal.value) {
        return false
      }
      break

    case conditions.GREATERTHAN:
      if (property <= goal.value) {
        return false
      }
      break
  }

  return true
}

/**
 * completionCheck() returns a boolean to indicate
 * whether all of the levels goals have been completed
 */
export function completionCheck(board) {
  const goals = board.metadata.goal.struct.required
  for (let index = 0; index < goals.length; index += 1) {
    if (!getGoalStatus(board, goals[index])) {
      return false
    }
  }

  return true
}

/**
 * placeholder/basic function for determining goal/failure status
 *
 */
export function exportAllGoalStatus(board, failure = null) {
  const status = { goals: [], failure }
  const goals = board.metadata.goal.struct.required
  let failureAdded = false
  for (let index = 0; index < goals.length; index += 1) {
    status.goals.push({
      value: board.components[goals[index].id.toString()][goals[index].property],
      condition: goals[index].condition,
      status: getGoalStatus(board, goals[index]),
      systemAdded: goals[index].systemAdded,
      triggeredFailure: (goals[index].id === failure.id
                         && goals[index].condition === failure.condition
                         && goals[index].property === failure.property)
    })
    if (status.goals[status.goals.length - 1].triggeredFailure === true) {
      failureAdded = true
    }
  }

  if (failureAdded === false) {
    status.goals.push({
      value: failure.value,
      conditon: failure.condition,
      status: false,
      systemAdded: true,
      triggeredFailure: true
    })
  }

  return status
}

/**
 * getGoalStatus() returns a string depending on what
 * is responsible for a given failure condition
 * NOT YET IMPLEMENTED
 */
export function getFailureReason(board, goals, maxTime, timestep) {
  if (timestep >= maxTime) {
    return {
      value: maxTime,
      type: 'time',
      property: 'time',
      condition: 'lt'
    }
  }

  for (let index = 0; index < goals.length; index += 1) {
    switch (goals[index].condition) {
      case conditions.EQUAL:
        if (board.components[goals[index].id.toString()][goals[index].property]
          > goals[index].value) {
          return goals[index]
        }

        break
      case conditions.LESSTHAN:
        if (board.components[goals[index].id.toString()][goals[index].property]
          >= goals[index].value) {
          return goals[index]
        }

        break
    }
  }

  return 'No failure state.'
}
