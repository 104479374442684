<template>
  <div class="auth-background">
    <v-card class="auth-ctr" elevation="5" outlined>
      <LogoCard/>
      <v-form ref="loginForm" id="login-form" v-model='isValid' @submit.prevent='login'>
        <v-text-field
          v-model='email'
          label='Email'
          hint='Enter your email address.'
          :rules="[formRules.email]"
          required
        ></v-text-field>
        <PasswordField
          :show='show'
          @toggle-show='toggleShow'
          :rules="[formRules.generic]"
          v-model='password'/>
        <div class='forgot-password-wrapper'>
          <v-btn class='forgot-password-btn pa-0' color='#4cbbcc' text x-small @click.prevent='navigateToForgotPassword'>Forgot Password?</v-btn>
        </div>
        <v-btn
          block
          type='submit'
          form='login-form'
          color='primary'
          class='login-btn'
          :disabled="!isValid"
        >Log In</v-btn>
      </v-form>
      <div class='spacer'>or</div>
      <v-btn to='/create-account/' color='secondary'>Create New Account</v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PasswordField from '@/components/auth/PasswordField.vue'
import LogoCard from '@/components/LogoCard.vue'
import aws from '@/auth/aws'
import { routeNames } from '@/vars'

export default {
  name: 'Login',
  components: {
    PasswordField,
    LogoCard
  },
  data() {
    return {
      email: '',
      password: '',
      show: false,
      isValid: false,
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    toggleShow() {
      this.show = !this.show
    },
    async login() {
      this.enableLoadingDialog()
      try {
        await aws.authenticateUser(this.email, this.password)
      } catch ({ code }) {
        let message = `An error occurred: ${code}. Please try again.`
        if (code === 'NotAuthorizedException') {
          message = 'Incorrect email address or password. Please try again.'
        } else if (code === 'PasswordResetRequiredException') {
          message = 'Password reset required. Please click on the Forgot Password button below and follow the instructions.'
        }
        this.showMessage(message)
        this.$refs.loginForm.reset()
        this.disableLoadingDialog()
        return
      }

      try {
        // Check if player exists
        let player = await aws.getPlayer().then((res) => res.data.player)
        // If not, create
        if (!player || !player.user_id) {
          player = await aws.createPlayer().then((res) => res.data.player)
        }
        // Set player record in storage
        this.$api.storage.setItem('Player', player)
        this.showMessage('Sign in successful. Welcome to Parallel!')
        setTimeout(() => this.rtr.push({ name: routeNames.LEVEL_SELECT }), 1000)
      } catch (error) {
        this.showMessage(error.message)
      } finally {
        this.disableLoadingDialog()
      }
    },
    navigateToForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' })
    }
  },
}
</script>

<style>
.forgot-password-wrapper {
  display: flex;
}
.forgot-password-btn {
  color: red;
}
.login-btn {
  margin: 1rem 0 0.5rem 0;
}
</style>
