export default class Mutation {
  // Default duration of a mutation is 100
  constructor(data) {
    this._data = data
    this.data.duration = data.duration || 100
  }

  get data() {
    return this._data
  }

  get duration() {
    return this.data.duration
  }

  set duration(duration) {
    this.data.duration = duration
  }

  get key() {
    return this.data.key
  }

  get value() {
    return this.data.value
  }

  get element() {
    return this.data.element
  }

  mutate() {
    if (this.value === undefined) {
      // console.error('Value must be set or passed to _mutate')
    }
    this._mutate(this.value)
  }

  _mutate(value) {
    this.element[this.key] = value
  }
}
