/**
 * simulation mode enum:
 * TEST - single random simulation
 * SUBMIT - random results until failure or budget reached
 * ABSOLUTE - single simulation with all threads moving every step
 */
export const modes = {
  TEST: 'TEST',
  SUBMIT: 'SUBMIT',
  ABSOLUTE: 'ABSOLUTE',
}

/**
 * movement type enum:
 * DEFAULT - threads moved every step if able
 * RANDOM - threads receive random movement budgets
 * EXTREME - a single thread moves until it can no loner move,
 *  all other threads move 1 cell, repeat
 * SIGNALED - whenever a thread reaches a signal,
 *  other threads also attempt to reach that signal
 */
export const movements = {
  DEFAULT: 'default',
  RANDOM: 'random',
  EXTREME: 'extreme',
  SIGNALED: 'signaled'
}

/**
 * component type enum
 */
export const components = {
  THREAD: 'thread',
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  SIGNAL: 'signal',
  SEMAPHORE: 'semaphore',
  SWITCH: 'switch',
  CONDITIONAL: 'conditional',
  EXCHANGE: 'exchange',
}

/**
 * pickup/package spec enums
 */
export const pickups = {
  UNLIMUNCONDITION: 'unlimUncondition',
  UNLIMCONDITION: 'unlimCondition',
  LIMCONDITION: 'limCondition',
}
