<template>
  <div class="landing-background">
    <v-card elevation="3" outlined class='content'>
      <LogoCard/>
      <div class='actions-box'>
        <div class="action-ctr play-action">
          <v-btn @click="play">Play</v-btn>
          <p>Play without logging in. You will not be able to save or participate in a study.</p>
        </div>
        <div class='action-ctr auth-action'>
          <div class='auth-buttons'>
            <v-btn small type="button" @click.prevent='login'>Log In</v-btn>
            <v-btn small type="button" @click.prevent='privacy'>Create Account</v-btn>
          </div>
          <p>Log in or create an account to save your data and participate in a study.</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import LogoCard from '@/components/LogoCard.vue'
import { routeNames } from '../vars'

export default {
  name: 'Landing',
  components: {
    LogoCard
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$api.user.isAuthenticated()) {
        next({ name: routeNames.LEVEL_SELECT })
      } else {
        next()
      }
    })
  },
  methods: {
    play() {
      this.$router.push({ name: routeNames.LEVEL_SELECT })
    },
    login() {
      this.$router.push({ name: routeNames.LOGIN })
    },
    privacy() {
      this.$router.push({ name: routeNames.PRIVACY_AGREEMENT })
    }
  },

}
</script>

<style scoped>
.landing-background {
  width: 100%;
  height: 100%;
  background: url('../assets/levelBackground.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  border-radius: 5px;
}

.actions-box {
  width: 600px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-ctr {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 1rem;
  border-radius: 5px;
}

.play-action {
  background-color: rgb(240, 240, 240);
}

.auth-action {
  background-color: rgb(255, 243, 228);
}

.auth-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
