/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import * as d3 from 'd3'

const sortElementsForDraw = (boardElements, linkElements) => {
  if (boardElements.length <= 0) return []
  let elements = boardElements
  if (linkElements.length > 0) {
    let currentIndex = 0
    let lastTrackIndex = 0
    for (let element of boardElements) {
      if (!element.isTrackElement) {
        lastTrackIndex = currentIndex
        break
      }
      currentIndex += 1
    }
    elements = [...elements.slice(0, lastTrackIndex), ...linkElements, ...elements.slice(lastTrackIndex, elements.length)]
  }
  return elements
}

const getDefaultState = () => ({
  mapD3: {},
  map: {},
  ctx: {},
  zoom: {},
  transform: d3.zoomIdentity,
  areLinksVisible: true
})

const state = getDefaultState()
const getters = {
  mapD3: (state) => state.mapD3,
  map: (state) => state.map,
  ctx: (state) => state.ctx,
  zoom: (state) => state.zoom,
  transform: (state) => state.transform,
  scale: (state, getters) => {
    const boardXDomain = getters.dimensions.level.width
    const boardXRange = Math.min(getters.dimensions.board.width, getters.mapWidth)
    const boardYDomain = getters.dimensions.level.height
    const boardYRange = Math.min(getters.dimensions.board.height, getters.mapHeight)
    // TODO: gui domain dynamically
    const guiXDomain = 100
    const guiYDomain = 100
    return {
      board: {
        y: d3.scaleLinear().domain([0, boardYDomain]).range([0, boardYRange]),
        x: d3.scaleLinear().domain([0, boardXDomain]).range([0, boardXRange])
      },
      gui: {
        y: d3.scaleLinear().domain([0, guiYDomain]).range([0, getters.mapHeight]),
        x: d3.scaleLinear().domain([0, guiXDomain]).range([0, getters.mapWidth]),
      }
    }
  },
  isOnTrack: (state, getters) => (mouseY, mouseX) => {
    let y = mouseY
    let x = mouseX
    // Transform and scale mouse coordinates to board cell
    x -= getters.transform.x
    y -= getters.transform.y
    x /= getters.transform.k
    y /= getters.transform.k
    const [newY, newX] = [getters.scale.board.y.invert(y - 50), getters.scale.board.x.invert(x - 50)]
    const snappedY = Math.round(newY)
    const snappedX = Math.round(newX)

    // Check if mouse board cell matches any board cells of tracks
    let flag = false
    getters.boardTrackElements.forEach((te) => {
      if (te.cell[0] === snappedY && te.cell[1] === snappedX) {
        flag = true
      }
    })
    return flag
  },
  isTrackOccupied: (state, getters) => (draggedEl, y, x) => {
    let flag = false
    let elements = getters.boardImageElements
    // If draggedElement is from board (not toolbar) then it must be removed from elements being considered
    if (draggedEl.isBoardElement) {
      elements = elements.filter((el) => el.id !== draggedEl.id)
    }
    elements.forEach((be) => {
      if (be.detectCollisionByPoint(y, x)) {
        flag = true
      }
    })
    return flag
  },
  areLinksVisible: (state) => state.areLinksVisible
}
const actions = {
  initMap: async ({ dispatch, getters }, payload) => {
    dispatch('setMapComponents', payload)
    await dispatch('bindMapFeaturesToElements')
    // Do not enable zoom, primarily when used in LevelSelect
    if (!getters.isMapDisabled) {
      await dispatch('initZoom')
    }
    dispatch('callZoomOffset')
    dispatch('draw')
  },
  setMapComponents: ({ commit }, { mapD3, map, ctx, mapCtr }) => {
    commit('setMapD3', mapD3)
    commit('setMap', map)
    commit('setCtx', ctx)
    commit('setMapCtr', mapCtr)
  },
  bindMapFeaturesToElements: async ({ dispatch, getters }) => {
    await dispatch('bindScale', getters.scale)
    await dispatch('bindTransform', getters.transform)
  },
  initZoom: async ({ commit, dispatch }) => {
    // This is the only mouse event in the system that is not controlled by the canvas itself in Map.vue
    const zoom = d3.zoom()
      .filter(() => {
        // Map drag events must be accompanied with shift key
        if (d3.event.type === 'mousedown') {
          return d3.event.shiftKey
        }
        return true
      })
      .scaleExtent([0.5, 5])
      .on('zoom', (() => dispatch('draw')))
    commit('setZoom', zoom)
    if (state.mapD3._groups) {
      state.mapD3.call(zoom)
      // Prevent double clicking
      state.mapD3.on('dblclick.zoom', null)
    }
  },
  callZoomOffset: ({ getters, dispatch }) => {
    let xOffset
    let yOffset
    const boardWidth = getters.dimensions.board.width
    const boardHeight = getters.dimensions.board.height
    const mapWidth = getters.mapWidth
    const mapHeight = getters.mapHeight
    const widthMin = Math.min(boardWidth, mapWidth)
    const widthMax = Math.max(boardWidth, mapWidth)
    const heightMin = Math.min(boardHeight, mapHeight)
    const heightMax = Math.max(boardHeight, mapHeight)
    const xScale = d3.scaleLinear().domain([0, getters.isMapDisabled ? widthMax : widthMin]).range([0, 1])
    const yScale = d3.scaleLinear().domain([0, getters.isMapDisabled ? heightMax : heightMin]).range([0, 1])

    const scaleX = xScale(widthMin)
    const scaleY = yScale(heightMin)
    // If mapWidth is the biggest
    if (getters.mapWidth === widthMax) {
      // 1 is the only difference because scaleX and scaleY are multiplied dirrectly to board width
      xOffset = Math.abs((getters.mapWidth - boardWidth * scaleX)) / 2
      yOffset = Math.abs((getters.mapHeight - boardHeight * scaleY)) / 2
    } else {
      xOffset = Math.abs((getters.mapWidth - boardWidth) * scaleX) / 2
      yOffset = Math.abs((getters.mapHeight - boardHeight) * scaleY) / 2
    }
    dispatch('setTransform', d3.zoomIdentity.translate(xOffset, yOffset).scale(scaleY, scaleX).translate(-20, -20))
    // Calling right away prevents jumping on first zoom
    if (!getters.isMapDisabled && state.mapD3._groups) {
      getters.mapD3.call(getters.zoom.transform, getters.transform)
    }
  },
  draw: ({ getters, dispatch }) => {
    const ctx = getters.ctx
    if (ctx.fillStyle) {
      ctx.clearRect(0, 0, getters.mapWidth, getters.mapHeight)
      if (!getters.transform) {
        console.error('Transform must be set before initial draw')
      }
    // Check for zoom event
      if (d3.event) {
        dispatch('setTransform', d3.event.transform)
      }
      const { y, x, k } = getters.transform
      ctx.save()
      ctx.translate(x, y)
      ctx.scale(k, k)

      // Specifically draw tracks first, then draw the links, finally draw other elements
      let drewLinks = false
      let boardElements = getters.boardElements
      let linkElements = getters.linkElements
      for (let i = 0; i < boardElements.length; i++) {
        if (!boardElements[i].isTrackElement && !drewLinks && getters.areLinksVisible) {
          for (let j = 0; j < linkElements.length; j++) {
            linkElements[j].draw(ctx)
          }
          drewLinks = true
        }
        boardElements[i].draw(ctx)
      }

      // const elements = sortElementsForDraw(getters.boardElements, getters.linkElements)
      // elements.forEach((el) => el.draw(ctx))

      ctx.restore()
      // GUI elements don't need to be scaled
      getters.guiElements.forEach((el) => el.draw(ctx))
    }
  },
  setTransform: ({ dispatch, commit }, transform) => {
    commit('setTransform', transform)
    dispatch('bindTransform', transform)
  },
  updateMapElements: async ({ getters, dispatch }, { width, height }) => {
    await dispatch('setMapSize', { width, height })
    await dispatch('bindScale', getters.scale)
    dispatch('draw')
  },
  toggleLinksVisibility: ({ commit }, flag) => {
    commit('toggleLinksVisibility', flag)
  },
  destroyMap({ commit }) {
    commit('destroyMap')
  }
}

const mutations = {
  setMapD3(state, mapD3) {
    state.mapD3 = mapD3
  },
  setMapCtr(state, mapCtr) {
    state.mapCtr = mapCtr
  },
  setMap(state, map) {
    state.map = map
  },
  setCtx(state, ctx) {
    state.ctx = ctx
  },
  setZoom(state, zoom) {
    state.zoom = zoom
  },
  setTransform(state, transform) {
    state.transform = transform
  },
  toggleLinksVisibility(state, flag) {
    state.areLinksVisible = flag || !state.areLinksVisible
  },
  destroyMap(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  sortElementsForDraw
}
