import ImageElement from './ImageElement'
import LockModifier from './LockModifier'
import { elementType } from '../vars'

export default class Semaphore extends ImageElement {
  get suffix() {
    if (!this.spec) return 'Inactive'
    return this.capitalize(this.spec)
  }

  get type() {
    return elementType.SEMAPHORE
  }

  get name() {
    return `${this.type}${this.suffix}`
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  get isDraggable() {
    if (this.isToolbarElement) return true
    return !!this.userPlaced
  }

  get isToggleable() {
    return !this.isToolbarElement
  }

  get isLinkable() {
    return !this.isToolbarElement
  }

  get image() {
    if (!this.spec) {
      console.log('spec is not defined')
      return this.primaryImage
    }
    // Semaphore is inactive by default in the toolbar
    return this.spec === 'active' ? this.primaryImage : this.secondaryImage
  }

  get modifiers() {
    const modifiers = []
    if (this.locked) {
      const data = {
        parent: this,
      }
      modifiers.push(new LockModifier(data))
    }
    return modifiers.flat()
  }

  onToggle() {
    if (this.locked) {
      console.log('This sempahore cannot be unlocked by the user')
      return
    }
    if (this.spec === 'active') {
      this.spec = 'inactive'
    } else {
      this.spec = 'active'
    }
  }

  create(data) {
    return new Semaphore(data)
  }
}
