/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import ImageElement from './ImageElement'
import ConditionalModifier from './ConditionalModifier'
import { elementType } from '../vars'

export default class Conditional extends ImageElement {
  constructor(data) {
    super(data)
    this._directionsTypes = data.directionsTypes
  }

  get suffix() {
    // Only draw empty conditionals and then modifiers will be added conditionally
    return this.capitalize('empty')
  }

  get type() {
    return elementType.CONDITIONAL
  }

  get directionsTypes() {
    return this._directionsTypes
  }

  get modifiers() {
    const modifiers = []
    // Loop through directionsTypes (array of arrays) and create conditional modifier objects of values
    this.directionsTypes.forEach((dArray, i) => {
      const orientation = i
      let modifiersArray = dArray.map((dItem) => {
        if (dItem !== 'Empty') {
          const data = {
            parent: this,
            orientation,
            type: dItem
          }
          return new ConditionalModifier(data)
        }
      })
      // Filter the resulting array of any values that are falsey
      modifiersArray = modifiersArray.filter((val) => !!val)
      // Add array to modifiers array of arrays
      modifiers.push(modifiersArray)
    })
    return modifiers.flat()
  }
}
