import { scaleLinear } from 'd3'

export default class ElementModifier {
  constructor(data) {
    this._data = data
    this._width = 10
    this._scale = 0.5
  }

  get data() {
    return this._data
  }

  get status() {
    return this.data.status
  }

  get type() {
    return this.data.type
  }

  get layout() {
    return this._layout
  }

  get orientation() {
    return this.data.orientation || 0
  }

  get parent() {
    return this.data.parent
  }

  set parent(parent) {
    this.data.parent = parent
  }

  get width() {
    return this._width
  }

  get height() {
    return this.width
  }

  get radius() {
    return this.width / 2
  }

  get color() {
    return this._color
  }

  set color(val) {
    this._color = val
  }

  get scale() {
    return this._scale
  }

  set scale(val) {
    this._scale = val
  }

  get parentXScale() {
    return scaleLinear()
      .domain([0, 10])
      .range([0, this.parent.width - (this.width + this.radius)])
  }

  get parentYScale() {
    return scaleLinear()
      .domain([0, 10])
      .range([0, this.parent.height - (this.width + this.radius)])
  }

  calculateSizeScale() {
    // TODO: implement dynamic scaling
    return 0.55
  }

  draw(ctx, parentX, parentY) {
    ctx.save()
    const dx = this.parentXScale(this.coordinates[0]) + parentX
    const dy = this.parentYScale(this.coordinates[1]) + parentY
    ctx.translate(dx, dy)
    ctx.scale(this.scale, this.scale)
    const p = new Path2D(this.path)
    ctx.fillStyle = this.color
    ctx.fill(p)
    ctx.restore()
  }
}
