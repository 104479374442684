const mixins = {
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
  computed: {
    rtr() {
      return this.$router
    },
    rt() {
      return this.$route
    },
    formRules() {
      return {
        generic: (v) => !!v || 'Required field',
        email: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        password: (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
          const message = 'Must include eight characters and at least one capital letter, one number, and one special character.'
          return (pattern.test(v) || message)
        },
        study: (v) => {
          const pattern = /^[a-z0-9]{6}$/i
          const message = 'Study ID must be 6 alphanumeric characters.'
          return (pattern.test(v) || message)
        }
      }
    }
  },
}

export default mixins
