export default class Element {
  constructor(data) {
    this._data = data
    const { width, scale, transform, cell } = data
    this.data.scale = scale || null
    this.data.cell = cell || []
    this.data.scale = scale || { y: null, x: null }
    this.data.transform = transform || { k: 1, x: 0, y: 0 }
    // Default width, assuming square element
    this._width = width || 100
  }

  get data() {
    return this._data
  }

  get id() {
    return this.data.id
  }

  set id(id) {
    this.data.id = id
  }

  get color() {
    return this.data.color
  }

  get type() {
    return this.data.type
  }

  set type(type) {
    this.data.type = type
  }

  get cell() {
    return this.data.cell
  }

  set cell(val) {
    this.data.cell = val
  }

  get width() {
    return this._width
  }

  set width(val) {
    this._width = val
  }

  get height() {
    return this.width
  }

  get radius() {
    return this.width / 2
  }

  get halfRadius() {
    return this.radius / 2
  }

  get hitSlop() {
    return 30
  }

  get scale() {
    return this.data.scale
  }

  set scale(scale) {
    this.data.scale = scale
  }

  get transform() {
    return this.data.transform
  }

  set transform(transform) {
    this.data.transform = transform
  }

  get coordinates() {
    return [this.scale.y(this.cell[0]), this.scale.x(this.cell[1])]
  }

  set coordinates([y1, x1]) {
    const [y, x] = this.makeTransformation(y1, x1)
    this.cell = [this.scale.y.invert(y - this.radius), this.scale.x.invert(x - this.radius)]
  }

  get isBoardElement() {
    return !!this.data.isBoardElement
  }

  get isPathElement() {
    return !!this.data.isPathElement
  }

  get isToolbarElement() {
    return !!this.data.isToolbarElement
  }

  get isActionable() {
    return this.isDraggable || this.isClickable
  }

  get isTrackElement() {
    return false
  }

  get userPlaced() {
    return this.data.userPlaced
  }

  get isClickable() {
    return false
  }

  get isDraggable() {
    return false
  }

  onMouseOver() {
    return null
  }

  onMouseOut() {
    return null
  }

  onMouseDown() {
    return null
  }

  onToggle() {
    return null
  }

  snapToGrid() {
    this.cell = [Math.round(this.cell[0]), Math.round(this.cell[1])]
  }

  makeTransformation(y1, x1) {
    let y = JSON.parse(JSON.stringify(y1))
    let x = JSON.parse(JSON.stringify(x1))
    // Only elements on the board are transformed, not gui
    if (this.isBoardElement) {
      x -= this.transform.x
      y -= this.transform.y
      x /= this.transform.k
      y /= this.transform.k
    }
    return [y, x]
  }

  makeBoardTransformation(y1, x1) {
    let y = JSON.parse(JSON.stringify(y1))
    let x = JSON.parse(JSON.stringify(x1))
    // Only elements on the board are transformed, not gui
    // if (this.isBoardElement) {
      x *= this.transform.k
      y *= this.transform.k
      x += this.transform.x
      y += this.transform.y
    // }
    // return this.transform.invert([y, x])
    return [y, x]
  }

  // Should return center
  getScreenCoordinates() {
    if (!this.scale.y) {
      return window ? [window.innerHeight / 2, window.innerWidth / 2] : [0, 0]
    }

    const [y, x] = this.coordinates
    let newY = y
    let newX = x
    if (this.isBoardElement) {
      newX *= this.transform.k
      newY *= this.transform.k
      newX += this.transform.x
      newY += this.transform.y
    }
    return [newY + this.radius, newX + this.radius]
  }

  detectCollisionByPoint(y, x) {
    const [y2, x2] = this.getScreenCoordinates()
    return Math.abs(y2 - y) < this.radius && Math.abs(x2 - x) < this.radius
  }

  capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
}
