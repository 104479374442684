<template>
  <v-card min-width='250' @mousemove='mouseMove' @mouseup='mouseUp'>
    <v-card-title class='keymap-title'>Game Controls</v-card-title>
    <v-card-text>
      <div>
        <span class='action-detail' v-if="operatingSystem !== 'Mac'">Right Click: </span>
        <span class='action-detail' v-if="operatingSystem === 'Mac'">Ctrl + Click: </span>
        Toggle element
      </div>
      <div>
        <span class='action-detail' v-if="operatingSystem !== 'Mac'">Alt + Click: </span>
        <span class='action-detail' v-if="operatingSystem === 'Mac'">Option + Click: </span>
        Link element
      </div>
      <div><span class='action-detail'>Shift + Click + Drag: </span>Move map</div>
      <div><span class='action-detail'>Mouse Scroll: </span>Zoom map in/out</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'KeyMap',
  data() {
    return {
      draggedEl: null,
      startCell: null
    }
  },
  computed: {
    ...mapGetters(['operatingSystem', 'browser', 'isDraggingElement', 'select', 'draggedElementPosition'])
  },
  methods: {
    ...mapActions(['setIsDraggingElement', 'showMessage', 'draw', 'setDraggedElementPosition']),
    mouseUp(e) {
      this.startCell = this.draggedElementPosition
      const el = this.select(e.y, e.x)
      this.draggedEl = el
      this.draggedEl.coordinates = [e.y, e.x]
      if (!this.isSimulationActive && this.isDraggingElement) {
          this.setIsDraggingElement(false)
          this.returnDraggedEl()
          this.showMessage('Elements must be placed on path!')
      }
      this.draw()
    },
    mouseMove(e) {
      const { y, x } = e
      const el = this.select(y, x)
      // If an element is being dragged
      if (this.isDraggingElement) {
        this.draggedEl = el
        this.draggedEl.coordinates = [y, x]
      }
      this.draw()
    },
    returnDraggedEl() {
      this.draggedEl.cell = this.startCell
      this.startCell = null
      this.setDraggedElementPosition(null)
      this.resetDraggedEl()
    },
    resetDraggedEl() {
      this.draggedEl = null
    }
  }
}
</script>

<style scoped>
  .keymap-ctr {
    min-width: 245px;
  }
  .keymap-title {
    padding-top: 0.5rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }
  .action-detail {
    font-weight: 700;
    font-size: 1rem;
  }

</style>
