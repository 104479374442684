<template>
  <div class="auth-background">
    <v-card class='overview-ctr'>
      <v-container class='card-ctr' v-if='!isLoading'>
        <h1> {{title}} </h1>
        <h3 v-if='code'>Code: {{code}}</h3>
        <br>
        <p v-if='body'>{{body}}</p>
        <br>
        <v-btn color='primary' @click.prevent='submit'>Join Activity</v-btn>
      </v-container>
      <h3 v-else>Loading activity...</h3>
    </v-card>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import aws from '@/auth/aws'
import { routeNames } from '../../vars'

export default {
  name: 'ActivityOverview',
  props: {
    activityCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      activity: {},
      didConsent: false
    }
  },
  computed: {
    code() {
      return this.activity.code
    },
    body() {
      return this.activity.body
    },
    title() {
      return this.activity.title
    }
  },
  methods: {
    ...mapActions(['showMessage', 'enableLoadingDialog', 'disableLoadingDialog']),
    submit() {
      this.enableLoadingDialog()
      aws.setActivityById(this.activity.id)
        .then((res) => {
          const activity = res.data.activity
          this.$api.storage.setItem('Activity', activity)
          const message = `You have joined activity ${activity.code}. You are ready to participate. Good luck!`
          this.showMessage(message)
        })
        .catch(({ response }) => this.showMessage(response.data))
        .finally(() => {
          setTimeout(() => {
            this.$router.push({ name: routeNames.LEVEL_SELECT })
            this.disableLoadingDialog()
            }, 3000)
          })
    }
  },
  mounted() {
    this.isLoading = true
    this.enableLoadingDialog()
    aws.getActivityByCode(this.activityCode)
      .then(({ data }) => {
        this.activity = data.activity
      })
      .catch(((error) => {
        this.showMessage(error)
        setTimeout(() => this.$router.push({ name: routeNames.LEVEL_SELECT }), 3000)
      }))
      .finally(() => {
        this.isLoading = false
        this.disableLoadingDialog()
      })
  }
}
</script>

<style scoped>
.overview-ctr {
  width: 500px;
  padding: 1rem;
}
.card-ctr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
