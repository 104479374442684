<template>
    <div v-if='!isLoading' class='tutorial-dialog-background'>
      <div v-show='!isSimulationActive' class='tutorial-dialog-ctr' :style='dialogStyle' ref="tutorialDialogCtr" >
        <div v-if='title' class='tutorial-title no-select'>{{title}}</div>
        <div class='tutorial-row-ctr no-select' v-for='row in rows' :key='row.id'>
          <div v-if='!!row.src' class='tutorial-media-ctr'>
            <img class='tutorial-media' :src='getRowSrc(row)'/>
          </div>
          <div v-html='getFormattedHtmlFromRow(row)'></div>
        </div>
        <button v-if='hasNextButton' class='std-btn tutorial-btn no-select' @click.prevent='handleNext'>Next</button>
      </div>
    </div>
</template>

<script>
import * as has from 'lodash/has'
import { mapActions, mapGetters } from 'vuex'
import { actionType, elementType } from '@/vars'
import config from '@/config.json'

export default {
  name: 'TutorialDialog',
  data() {
    return {
      isLoading: false,
      hasUpdated: false,
      dialogStyle: {
        left: '0px',
        top: '0px',
      }
    }
  },
  methods: {
    ...mapActions(['advanceTutorialStep', 'checkTutorialRequiredAction']),
    rowHasMedia(row) {
      return !!row.src
    },
    getRowSrc(row) {
      // TODO: Support placeholder
      return require(`../../assets/icons/${row.src}.png`)
    },
    handleNext() {
      this.checkTutorialRequiredAction({ type: actionType.BUTTON_CLICK, target: elementType.NEXT_BUTTON })
    },
    getCurrentTutorialStep() {
      const step = this.tutorialSteps[this.currentTutorialStepIndex]
      return (step && has(step, 'content') && has(step, 'requiredAction')) ? step : { content: {}, requiredAction: { type: null, target: null } }
    },
    getContent() {
      return this.getCurrentTutorialStep().content
    },
    getFormattedCoordinates() {
      const position = this.getCurrentTutorialStep().position
      let coordinates = []
      if (!position) {
        coordinates = [0, 0]
      } else if (!position.elementId) {
        const [yOffset, xOffset] = this.getXYOffset(position.relative)
        coordinates = [(window.innerHeight / 2) + yOffset, (window.innerWidth / 2) + xOffset]
      } else {
        const { y, x, type } = this.elementCoordinatesById(position.elementId)
        const [yOffset, xOffset] = this.getXYOffset(position.relative, type)
        coordinates = [y + yOffset, x + xOffset]
      }
      return [`${coordinates[0].toString()}px`, `${coordinates[1].toString()}px`]
    },
    getXYOffset(relative) {
      const dialog = this.$refs.tutorialDialogCtr || {}
      const dialogWidth = dialog.offsetWidth || 400
      const dialogHeight = dialog.offsetHeight || 200
      const iconWidth = 100
      const iconRadius = iconWidth / 2
      if (relative === 'TOP') {
        return [-dialogHeight - iconRadius, -(dialogWidth / 2)]
      }
      if (relative === 'RIGHT') {
        return [-(dialogHeight / 2), iconRadius]
      }
      if (relative === 'BOTTOM') {
        return [(dialogHeight / 2) - 75, -(dialogWidth / 2)]
      }
      if (relative === 'LEFT') {
        return [-(dialogHeight / 2), -dialogWidth]
      }
      return [-(dialogHeight / 2), -(dialogWidth / 2)]
    },
    setDialogStyle() {
      if (!this.isLoading && !this.isDraggingElement && !this.isSimulationActive) {
        const [top, left] = this.getFormattedCoordinates()
        this.dialogStyle = { left, top }
      }
    },
    getFormattedHtmlFromRow(row) {
      // TODO: replace with variables
      if (this.operatingSystem === 'Mac') {
        return row.html.replace('alt-clicking', 'option-clicking').replace('alt-click', 'option-click')
      }
      return row.html
    }
  },
  computed: {
    ...mapGetters(['tutorialSteps', 'currentTutorialStepIndex', 'elementCoordinatesById', 'isDraggingElement', 'isSimulationActive', 'isReflectionDialogActive', 'operatingSystem']),
    title() {
      return this.getContent().title
    },
    rows() {
      return this.getContent().rows
    },
    hasNextButton() {
      return this.getCurrentTutorialStep().requiredAction.target === 'NEXT_BUTTON'
    }
  },
  created() {
    this.setDialogStyle()
  },
  mounted() {
    this.isLoading = true
    this.$nextTick(() => {
      this.isLoading = false
    })
  },
  watch: {
    currentTutorialStepIndex: {
      handler() {
        setTimeout(() => {
          this.setDialogStyle()
        }, config.defaultWait)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.tutorial-dialog-background {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.tutorial-dialog-ctr {
  position: absolute;
  pointer-events: all;
  padding: 1.6rem 1rem;
  width: 400px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid var(--primary);
  background-color: #eeeeee;
  border-radius: 25px;
  pointer-events: none;
}
.tutorial-title {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.tutorial-row-ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.tutorial-text {
  font-weight: 500;
}
.tutorial-text-highlight {
  color: #bb271a;
}
.tutorial-media-ctr {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tutorial-media {
  height: 100px;
  margin-right: 1rem;
}
.tutorial-btn {
  background-color: #444c50;
  font-size: 1.2rem;
  padding: 0.7rem 0;
  width: 150px;
  margin-top: 1rem;
  pointer-events: all;
}
.tutorial-actions {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
</style>
