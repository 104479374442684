/* eslint-disable no-unused-vars */
import ElementModifier from './ElementModifier'

export default class ConditionalModifier extends ElementModifier {
  get color() {
    switch (this.type) {
      case 'limCondition': return 'red'
      case 'unlimCondition': return 'blue'
      case 'unlimUncondition': return 'green'
      default: return 'grey'
    }
  }

  get coordinates() {
    let coordinates
    const center = 5
    const smOffset = 1.7
    const lgOffset = 3.3
    switch (this.type) {
      case 'limCondition': coordinates = [
        [center + smOffset, center],
        [center, center + smOffset],
        [center - smOffset, center],
        [center, center - smOffset]]; break
      case 'unlimCondition': coordinates = [
        [center + lgOffset, center - smOffset],
        [center + smOffset, center + lgOffset],
        [center - lgOffset, center + smOffset],
        [center - smOffset, center - lgOffset]]; break
      case 'unlimUncondition': coordinates = [
        [center + lgOffset, center + smOffset],
        [center - smOffset, center + lgOffset],
        [center - lgOffset, center - smOffset],
        [center + smOffset, center - lgOffset]]; break
      default: coordinates = [
        [center + lgOffset, center + smOffset],
        [center - smOffset, center + lgOffset],
        [center - lgOffset, center - smOffset],
        [center + smOffset, center - lgOffset]]
    }
    if (!coordinates) console.error('incorrect case')
    return coordinates[this.orientation]
  }

  get path() {
    switch (this.type) {
      case 'limCondition': return 'M.28,3.19,12.81,24.5,25.53,3.19,19,.2H6.87Zm12.53,21.7L0,3.1,6.82,0H19l6.8,3.1ZM.56,3.28,12.82,24.11,25.25,3.28,18.93.4h-12Z'
      case 'unlimCondition': return 'M0,0H22.56V22.56H0Z'
      case 'unlimUncondition': return 'M.2,14.25l14-14,14,14-14,14ZM14.25,28.49,0,14.25,14.25,0,28.49,14.25ZM.4,14.25,14.25,28.09,28.09,14.25,14.25.4Z'
      default: return ''
    }
  }
}
