import PathElement from './PathElement'
import { elementType } from '../vars'

export default class Mouse extends PathElement {
  constructor(data) {
    super(data)
    this.data.coordinates = data.coords
    this.type = elementType.MOUSE
  }

  get coordinates() {
    const y = this.data.coordinates[0]
    const x = this.data.coordinates[1]
    return [y, x]
  }

  set coordinates([y1, x1]) {
    this.data.coordinates = [y1, x1]
  }

  get width() {
    return 0
  }

  get radius() {
    return 0
  }

  draw() {
    return null
  }
}
