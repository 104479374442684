/* eslint-disable no-shadow */
import { logEventTypes } from '@/store/modules/logEvents'
import elh from '@/store/modules/elementHelpers'
import config from '@/config.json'
import * as findIndex from 'lodash/findIndex'
import * as has from 'lodash/has'
import { elementType } from '../../vars'

const getDefaultState = () => ({
  levelData: {
    metadata: {
      boardDimensions: [],
      goal: {},
      level: {},
      skills: [],
      time: {}
    }
  },
  guiData: {},
  hasMapConfigBeenSet: false,
  mapConfig: {},
  isDraggingElement: false,
  draggedElementPosition: null
})

const state = getDefaultState()
const getters = {
  levelData: (state) => JSON.parse(JSON.stringify(state.levelData)) || { components: null, map: [], metadata: null },
  guiData: (state) => JSON.parse(JSON.stringify(state.guiData)),
  guiDataElements: (state, getters) => ({
    path: getters.guiData.elements.filter((el) => el.isPathElement),
    image: getters.guiData.elements.filter((el) => !el.isPathElement)
  }),
  components: (state, getters) => (getters.levelData.components ? Object.values(getters.levelData.components) : []),
  metadata: (state, getters) => getters.levelData.metadata,
  levelConfig: (state, getters) => {
    if (!getters.metadata) {
      return {}
    }
    return getters.metadata.level
  },
  isDraggingElement: (state) => state.isDraggingElement,
  draggedElementPosition: (state) => state.draggedElementPosition,
  hasMapConfigBeenSet: (state) => state.hasMapConfigBeenSet,
  mapItems: (state, getters) => {
    const mapItems = []
    if (!getters.levelData.map) return mapItems
    getters.levelData.map.forEach((row) => {
      row.forEach((mi) => {
        const mapItem = mi
        if (mapItem.layout) {
          mapItem.isBoardElement = true
          mapItems.push(mapItem)
        }
      })
    })
    return JSON.parse(JSON.stringify(mapItems))
  },
  mapConfig: (state) => state.mapConfig,
  mapWidth: (state, getters) => getters.mapConfig.width,
  mapHeight: (state, getters) => getters.mapConfig.height,
  isMapDisabled: (state) => state.mapConfig.isDisabled || false,
  iconSize: (state) => state.mapConfig.iconSize || 100,
  dimensions: (state, getters) => {
    const level = {
      width: getters.metadata.boardDimensions[0],
      height: getters.metadata.boardDimensions[1]
    }
    const configLevelDims = config.levelDimensions[getters.metadata.level.id]
    const board = {
      width: has(configLevelDims, 'width') ? configLevelDims.width : 100,
      height: has(configLevelDims, 'height') ? configLevelDims.height : 100
    }

    const gui = {
      width: level.width * getters.iconSize,
      height: level.height * getters.iconSize
    }
    return { level, board, gui }
  }
}
const actions = {
  initLevel: async ({ dispatch, getters }, { id, mapConfig }) => {
    const levelName = config.levelIdToNameMap[id]
    await dispatch('destroyStore')
    if (mapConfig) {
      await dispatch('setMapConfig', mapConfig)
    }
    await dispatch('getSetLevelData', levelName)
    await dispatch('addLogEvent', { type: logEventTypes.BEGIN_LEVEL_LOAD, name: levelName, order: id })
    const dynamicImageElements = await elh.createDynamicImageElements()
    dynamicImageElements.forEach((ie) => {
      dispatch('addImage', ie)
    })
    // Create and load these first, so they are painted first (below the image elements)
    await dispatch('createLoadPathElements', { elementData: getters.mapItems, isBoardElement: true })
    // Load dynamic imageElements
    await dispatch('createLoadImageElements', { elementData: getters.components, isBoardElement: true })
    if (!getters.isMapDisabled) {
      await dispatch('getSetGuiData')
      await dispatch('createLoadPathElements', { elementData: getters.guiDataElements.path, isBoardElement: false })
      await dispatch('createLoadImageElements', { elementData: getters.guiDataElements.image, isBoardElement: false })
      await dispatch('initLevelTutorial', levelName)
    }
    const data = await dispatch('getBoardSnapshotData')
    await dispatch('addLogEvent', { type: logEventTypes.BOARD_SNAPSHOT, board: data })
    await dispatch('addLogEvent', { type: logEventTypes.FINISH_LEVEL_LOAD, name: levelName, order: id })
  },
  destroyStore({ dispatch }) {
    dispatch('destroyLevel')
    dispatch('destroyElements')
    dispatch('destroyMap')
    dispatch('destroyReflection')
    dispatch('destroySimulation')
    dispatch('destroyTutorial')
    dispatch('destroyMessage')
  },
  destroyLevel({ commit }) {
    // commit('addLogEvent', { type: 'EXIT' })
    commit('resetLevel')
  },
  setMapConfig: async ({ commit }, mapConfig) => {
    commit('setMapConfig', mapConfig)
  },
  setMapSize: async ({ commit }, { width, height }) => {
    commit('setMapWidth', width)
    commit('setMapHeight', height)
  },
  getSetLevelData: async ({ commit }, levelName) => {
    if (!levelName) return
    const levelData = require(`@/data/levels/level_${levelName}.json`)
    commit('setLevelData', levelData)
  },
  setIsDraggingElement: ({ commit }, flag) => {
    commit('setIsDraggingElement', flag)
  },
  setDraggedElementPosition: ({ commit }, flag) => {
    commit('setDraggedElementPosition', flag)
  },
  getSetGuiData: async ({ commit, getters }) => {
    delete require.cache[require.resolve('@/data/guiData.json')]
    const guiData = require('@/data/guiData.json')
    // Do not include link indicator for levels 1 - 3
    guiData.elements.forEach((el) => {
      el.isGuiElement = true
    })
    if (config.levelsWithoutLinkIndicatorByName.includes(getters.metadata.level.id)) {
      const index = findIndex(guiData.elements, (el) => el.type === elementType.LINKS_INDICATOR)
      if (index !== -1) {
        guiData.elements.shift(index, 1)
      }
    }
    commit('setGuiData', guiData)
  },
  // levelData that reflects user changes - for solution checker
  getBoardData: async ({ getters, dispatch }) => {
    // TODO: this is so ugly but it works
    const components = await dispatch('serializeComponents')
    // Check for link elements, if link element add "linkTo" to top level of component
    getters.linkElements.forEach((le) => {
      const sourceID = le.sourceEl.id
      const targetID = le.targetEl.id
      components[sourceID].link = targetID
    })
    const levelData = await dispatch('getLevelData')
    const map = levelData.map || []
    map.forEach((row) => {
      row.forEach((mi) => {
        const mapItem = mi
        Object.values(components).forEach((comp) => {
          if (mapItem.coords[0] === comp.cell[0] && mapItem.coords[1] === comp.cell[1]) {
            if (!mapItem.components.includes(comp.id)) {
              mapItem.components.push(comp.id)
            }
          }
        })
      })
    })
    const data = {
      metadata: getters.metadata,
      map,
      components
    }
    return JSON.parse(JSON.stringify(data))
  },
  getBoardSnapshotData: async ({ dispatch }) => {
    const boardData = await dispatch('getBoardData')
    return { map: boardData.map, components: boardData.components }
  },
  getLevelData: ({ state }) => JSON.parse(JSON.stringify(state.levelData))

}
const mutations = {
  resetLevel: (state) => {
    Object.assign(state, getDefaultState())
  },
  setMapConfig: (state, mapConfig) => {
    state.mapConfig = mapConfig
    state.hasMapConfigBeenSet = true
  },
  setMapHeight(state, height) {
    state.mapConfig.height = height
  },
  setMapWidth(state, width) {
    state.mapConfig.width = width
  },
  setLevelData: (state, levelData) => {
    state.levelData = levelData
  },
  setGuiData: (state, guiData) => {
    state.guiData = guiData
  },
  setIsDraggingElement: (state, flag) => {
    state.isDraggingElement = flag
  },
  setDraggedElementPosition: (state, flag) => {
    state.draggedElementPosition = flag
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
