import Mutation from './Mutation'

export default class ChangeDirection extends Mutation {
  constructor(data) {
    super(data)
    this.sDirection = data.element.direction
    this.tDirection = data.value
  }

  get sDirection() {
    return this.data.sDirection
  }

  set sDirection(direction) {
    this.data.sDirection = direction
  }

  get tDirection() {
    return this.data.tDirection
  }

  set tDirection(direction) {
    this.data.tDirection = direction
  }

  mutate(elapsed) {
    const t = Math.min(1, (elapsed / this.duration))
    // For all mutations, make minor mutations
    const newDirection = this.sDirection * (1 - t) + this.tDirection * t
    this._mutate(newDirection)
  }
}
