import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class Thread extends ImageElement {
  get suffix() {
    switch (this.color) {
      case 1: return 'One'
      case 2: return 'Two'
      case 3: return 'Three'
      case 4: return 'Four'
      default: return 'Five'
    }
  }

  get type() {
    return elementType.THREAD
  }

  get name() {
    return `${this.type}${this.suffix}`
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  get isDraggable() {
    return false
  }

  get isActionable() {
    return true
  }

  onMouseOver() {
    this.data.onMouseOverAction()
  }

  onMouseOut() {
    this.data.onMouseOutAction()
  }

  draw(ctx) {
    // Save current context settings
    ctx.save()
    const y = this.scale.y(this.cell[0])
    const x = this.scale.x(this.cell[1])
    // Get center of image for shifting/rotating
    const dx = x + this.radius
    const dy = y + this.radius
    ctx.translate(dx, dy)
    ctx.rotate(this.angle * (Math.PI / 180))
    ctx.translate(-dx, -dy)
    ctx.drawImage(this.image, x, y, this.width, this.height)
    // Restore previous context settings
    ctx.restore()
  }
}
