import { elementType } from '@/vars'
import PathElement from './PathElement'
import TrackModifier from './TrackModifier'

export default class Track extends PathElement {
  constructor(data) {
    super(data)
    this.type = elementType.TRACK
    this.arrowsVisible = false
    this.clear = false
    this.arrowsArr = []
  }

  get directions() {
    return this.data.directions
  }

  get angle() {
    switch (this.orientation) {
      case 'n': return 270
      case 'e': return 0
      case 's': return 90
      case 'w': return 180
      default: return 0
    }
  }

  get isTrackElement() {
    return true
  }

  set displayArrows(flag) {
    this.arrowsVisible = flag
  }

  set clearArray(flag) {
    this.clear = flag
  }

  set setArrowsArr(modifiers) {
    this.arrowsArr = modifiers
  }

  get offset() {
    return [12, 12]
  }

  // get smallHitSlop() {
  //   return this.radius / 2
  // }

  // get bigHitSlop() {
  //   return this.radius
  // }

  // get xHitSlop() {
  //   if (this.layout === 'E') {
  //     if (['n', 's'].includes(this.orientation)) {
  //       return this.smallHitSlop
  //     }
  //   }
  //   if (this.layout === 'C') {
  //     if (['w', 'e'].includes(this.orientation)) {
  //       return this.smallHitSlop
  //     }
  //   }
  //   return this.bigHitSlop
  // }

  // get yHitSlop() {
  //   if (this.layout === 'E') {
  //     if (['w', 'e'].includes(this.orientation)) {
  //       return this.smallHitSlop
  //     }
  //   }
  //   if (this.layout === 'C') {
  //     if (['n', 's'].includes(this.orientation)) {
  //       return this.smallHitSlop
  //     }
  //   }
  //   return this.bigHitSlop
  // }

  // detectCollisionByPoint(y, x) {
  //   const [y2, x2] = this.getScreenCoordinates()
  //   const bool = Math.abs(y2 - y) < this.yHitSlop && Math.abs(x2 - x) < this.xHitSlop
  //   if (bool) console.log(this.data)
  //   return bool
  //   // return Math.abs(y2 - y) < yHitSlop && Math.abs(x2 - x) < xHitSlop
  // }

  get modifiers() {
    const modifiers = []
    if (this.directions.length === 1 && this.layout === 'E') {
      const direction = this.directions[0]
      const data = {
        parent: this,
        direction
      }
      modifiers.push(new TrackModifier(data))
    }
    return modifiers
  }

  draw(ctx) {
    ctx.save()
    const x = this.scale.x(this.cell[1]) + this.radius
    const y = this.scale.y(this.cell[0]) + this.radius
    ctx.translate(x, y)
    const p = new Path2D(this.path)
    ctx.rotate(this.angle * (Math.PI / 180))
    ctx.translate(-this.radius, -this.radius)
    ctx.fillStyle = this.fillStyle
    ctx.fill(p)
    ctx.translate(this.radius, this.radius)
    ctx.rotate(0 - (this.angle * (Math.PI / 180)))
    ctx.translate(-x, -y)
    if (this.arrowsVisible) {
      const offset = this.offset
      this.arrowsArr.forEach((trackMod) => {
        if (trackMod.modifiers) {
          trackMod.modifiers.draw(ctx, trackMod.x - offset[0], trackMod.y - offset[1])
        }
      })
    }
    this.arrowsArr = this.clear ? [] : this.arrowsArr
    ctx.restore()
  }

  get path() {
    switch (this.layout) {
      case 'E':
        return 'M100,37V63H0V37Z'
      case 'C':
        return 'M63,49v51H37V75A12,12,0,0,0,25,63H0V37H51A12,12,0,0,1,63,49Z'
      case 'G':
        return 'M100,37V63H75A12,12,0,0,0,63,75v25H37V75A12,12,0,0,0,25,63H0V37Z'
      default:
        return 'M100,37V63H75A12,12,0,0,0,63,75v25H37V75A12,12,0,0,0,25,63H0V37H25A12,12,0,0,0,37,25V0H63V25A12,12,0,0,0,75,37Z'
    }
  }
}
