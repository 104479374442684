<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Message',
  props: {
    vertical: {
      default: true,
      type: Boolean
    },
    top: {
      default: true,
      type: Boolean
    },
    bottom: {
      default: false,
      type: Boolean
    },
    left: {
      default: false,
      type: Boolean
    },
    right: {
      default: false,
      type: Boolean
    },
    color: {
      default: 'grey darken-4',
      type: String
    },
    multiline: {
      default: false,
      type: Boolean
    },
    timeout: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters(['message', 'isActionRequired', 'isMessageActive']),
    messageTimeout() {
      if (this.timeout) return this.timeout
      return this.isActionRequired ? 75000 : 4000
    }
  },
  methods: {
    ...mapActions(['deactivateMessage']),
    deactivate() {
      this.deactivateMessage()
    }
  }
}
</script>

<template>
  <v-snackbar
    :value="isMessageActive"
    :vertical="vertical"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :color="color"
    :multi-line="multiline"
    :timeout="messageTimeout"
    @input="deactivate"
  >
    {{message}}
    <template #action>
      <button @click='deactivateMessage'>Close</button>
    </template>
  </v-snackbar>
</template>
