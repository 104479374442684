/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import ImageElement from './ImageElement'
import SwitchModifier from './SwitchModifier'

export default class Switch extends ImageElement {
  get directions() {
    return this.data.directions
  }

  set directions(directions) {
    this.data.directions = directions
  }

  get current() {
    return this.data.current
  }

  set current(current) {
    this.data.current = current
  }

  get suffix() {
    return 'Empty'
  }

  get name() {
    return `switch${this.suffix}`
  }

  get alt() {
    return `Switch ${this.suffix}`
  }

  get isLinkable() {
    return true
  }

  get modifiers() {
    const modifiers = []
    // Loop through directionsTypes (array of arrays) and create conditional modifier objects of values
    this.directions.forEach((direction, i) => {
      const orientation = direction
      const data = {
        parent: this,
        orientation,
        status: i === this.current ? 'active' : 'inactive'
      }
      const modifier = new SwitchModifier(data)
      // Filter the resulting array of any values that are falsey
      // Add array to modifiers array of arrays
      modifiers.push(modifier)
    })
    // const currentData = {
    //   parent: this,
    //   orientation: this.current,
    //   status: 'active'
    // }
    // const currentModifier = new SwitchModifier(currentData)
    // modifiers.push(currentModifier)
    return modifiers.flat()
  }
}
