import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '@/components/Landing.vue'
import LevelSelect from '@/components/level/LevelSelect.vue'
import Level from '@/components/level/Level.vue'
import NotFound from '@/components/NotFound.vue'
import Login from '@/components/auth/Login.vue'
import CreateAccount from '@/components/auth/CreateAccount.vue'
import ForgotPassword from '@/components/auth/ForgotPassword.vue'
import OPMView from '@/components/opm/OPMView.vue'
import ResetPassword from '@/components/auth/ResetPassword.vue'
import Forbidden from '@/components/Forbidden.vue'
import PrivacyAgreement from '@/components/auth/PrivacyAgreement.vue'
import JoinActivity from '@/components/activity/JoinActivity.vue'
import ActivityOverview from '@/components/activity/ActivityOverview.vue'
import { routeNames } from '../vars'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: routeNames.LANDING,
    component: Landing,
  },
  {
    path: '/level-select/',
    name: routeNames.LEVEL_SELECT,
    component: LevelSelect
  },
  {
    path: '/level/:level/',
    name: routeNames.LEVEL,
    component: Level
  },
  {
    path: '/login/',
    name: routeNames.LOGIN,
    component: Login
  },
  {
    path: '/create-account/',
    name: routeNames.CREATE_ACCOUNT,
    component: CreateAccount,
  },
  {
    path: '/forgot-password/',
    name: routeNames.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: '/reset-password/',
    name: routeNames.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: '/opm-view/',
    name: routeNames.OPM_VIEW,
    component: OPMView,
  },
  {
    path: '/not-found/',
    name: routeNames.NOT_FOUND,
    component: NotFound,
  },
  {
    path: '/forbidden/',
    name: routeNames.FORBIDDEN,
    component: Forbidden,
  },
  {
    path: '/join-activity/',
    name: routeNames.JOIN_ACTIVITY,
    component: JoinActivity,
  },
  {
    path: '/activity-overview/:activityCode',
    name: routeNames.ACTIVITY_OVERVIEW,
    component: ActivityOverview,
    props: true
  },
  {
    path: '/privacy-agreement',
    name: routeNames.PRIVACY_AGREEMENT,
    component: PrivacyAgreement,
    props: true
  },
  {
    path: '*',
    redirect: '/not-found/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
