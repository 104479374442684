const getDefaultState = () => ({
  isAppLoading: false
})

const state = getDefaultState()
const getters = {
  isAppLoading: (state) => state.isAppLoading
}
const actions = {
  enableLoadingDialog: ({ commit }) => {
    commit('enableLoadingDialog')
  },
  disableLoadingDialog: ({ commit }) => {
    commit('disableLoadingDialog')
  }
}
const mutations = {
  enableLoadingDialog: (state) => {
    state.isAppLoading = true
  },
  disableLoadingDialog: (state) => {
    state.isAppLoading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
