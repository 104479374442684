/* eslint-disable no-unused-vars */
import ElementModifier from './ElementModifier'

export default class DeliveryCounter extends ElementModifier {
  get color() {
    return 'pink'
  }

  get scale() {
    return 0.7
  }

  get width() {
    return this.parent.radius * this.scale
  }

  get radius() {
    return this.width / 2
  }

  get fontConfig() {
    return {
      size: this.width * this.scale,
      type: 'sans-serif',
      color: 'black'
    }
  }

  get fontString() {
    const { size, type } = this.fontConfig
    return `${size}px ${type}`
  }

  get orientation() {
    return 1
  }

  getCoordinates(x, y) {
    let cx
    let cy
    if (this.orientation === 1) {
      cx = x + this.parent.radius
      cy = y - this.width
    } else if (this.orientation === 2) {
      cx = x + this.parent.width + this.width
      cy = y + this.parent.radius
    } else if (this.orientation === 3) {
      cx = x + this.parent.radius
      cy = y + this.parent.height + this.height
    } else {
      cx = x - this.width
      cy = y + this.parent.radius
    }
    return [cx, cy]
  }

  draw(ctx, parentX, parentY) {
    ctx.save()
    const [cx, cy] = this.getCoordinates(parentX, parentY)
    ctx.fillStyle = this.color
    ctx.beginPath()
    ctx.arc(cx, cy, this.width, 0, 2 * Math.PI)
    ctx.fill()

    ctx.stroke()
    ctx.font = this.fontString
    ctx.fillStyle = this.fontConfig.color
    const delivered = this.parent.data.delivered
    const denom = this.parent.data.denominator
    const string = `${delivered}/${denom}`
    const stringWidth = ctx.measureText(string).width
    const dx = cx - (stringWidth / 2)
    const dy = cy + (this.radius / 2)
    ctx.fillText(string, dx, dy)
    ctx.restore()
  }
}
