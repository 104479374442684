/**
 *    Script:         resultParser.js
 *    Description:    Uses result.json file to find, format and
 *                    retrun the a human readable goal information
 *
 *    Author:         Boyd Fox                    Date: 8/30/2020
 *    Notes:
 */

import result from './result.json'
import reflection from './reflection.json'

// This might become a factory if these get complicated enough.
const statuses = {
  DEFAULT: 'default',
  FAILURE: 'failure',
  SUCCESS: 'success'
}

const titles = {
  FAILURE: 'Unsuccessful Solution',
  SUCCESS: 'Successful Solution',
  DEFAULT: 'Default',
  GOAL: 'Goals',
  PLACEHOLDER: 'Placeolder text for describing the .gif above/below this.',
}

const titleIcons = {
  FAILURE: 'failure.png',
  SUCCESS: 'success.png'
}

const subtitles = {
  DEFAULT: 'default',
  FAILURE: 'Whoops!',
  SUCCESS: 'Good job!'
}

const description = {
  DEFAULT: 'default',
  FAILURE: 'Looks like something went wrong.',
  SUCCESS: 'You met the level goal!'
}

const headers = {
  DEFAULT: 'Default header:',
  FAILURE: 'Details on why the goals were not met:',
  SUCCESS: 'Knowledge Unlocked:'
}

export class ReflectionFormatter {
  formatContent(goal, status, level, simType) {
    const goals = goal.struct.required
    const content = this.generateBase()
    content.numUserPlacedComponents = this.getComponentNum(level)
    content.simType = simType
    let failureAdded = false
    for (let index = 0; index < goals.length; index += 1) {
      if (status.goals[index].systemAdded !== true || status.goals[index].triggeredFailure) {
        let goalStatus = null
        if (status.goals.length > index) {
          goalStatus = status.goals[index]
        }

        content.goals.push(this.formatGoal(goals[index], goalStatus, level.components[goals[index].id]))
        if (status.goals[index].triggeredFailure) {
          failureAdded = true
        }
      }
    }

    if (status.failure != null && status.failure !== 'No failure state.') {
      content.title = titles.FAILURE
      content.titleIcon = titleIcons.FAILURE
      content.subtitle = subtitles.FAILURE
      content.description = description.FAILURE
      content.status = statuses.FAILURE
      content.header = headers.FAILURE
      if (!failureAdded) {
        content.goals.push(this.formatGoal(status.failure, status.goals[status.goals.length - 1], null))
      }
    } else {
      content.title = titles.SUCCESS
      content.titleIcon = titleIcons.SUCCESS
      content.subtitle = subtitles.SUCCESS
      content.description = (reflection[`level_${level.metadata.level.id}`].success !== '') ? reflection[`level_${level.metadata.level.id}`].success : description.SUCCESS
      content.status = statuses.SUCCESS
      content.header = headers.SUCCESS
    }

    for (let index = 0; index < reflection[`level_${level.metadata.level.id}`].description.length; index++) {
      content.reflections.push({
        description: reflection[`level_${level.metadata.level.id}`].description[index],
        media: reflection[`level_${level.metadata.level.id}`].media[index].src,
        mediaType: reflection[`level_${level.metadata.level.id}`].media[index].type
      })
    }
    return content
  }

  generateBase() {
    return {
      status: statuses.DEFAULT,
      title: titles.DEFAULT,
      titleIcon: titleIcons.failure,
      subtitle: subtitles.DEFAULT,
      description: description.DEFAULT,
      simType: titles.DEFAULT,
      numUserPlacedComponents: 0,
      header: headers.DEFAULT,
      goals: [
      ],
      reflections: [
      ]
    }
  }

  formatGoal(goal, status = null, component = null) {
    try {
      return {
        title: result[goal.type][goal.property][goal.condition][(status.status) ? 'success' : 'failure'],
        icon: `${goal.type}.png`,
        status: (status.status) ? statuses.SUCCESS : statuses.FAILURE,
        actual: status.value,
        target: goal.value,
        comparison: goal.condition,
        component: (component != null) ? {
          id: component.id,
          type: component.type,
          spec: component.spec,
          color: component.color
        } : null,
        triggeredFailure: (status.triggeredFailure === true)
      }
    } catch (err) {
      return {
        title: `Failed to retrieve: ${goal.type}, ${goal.property}, ${goal.condition}`,
        status: '',
        actual: '',
        target: '',
        icon: ''
      }
    }
  }

  getComponentNum(level) {
    try {
      let componentNum = 0
      for (const key in level.components) {
        if (Object.prototype.hasOwnProperty.call(level.components[key], 'userPlaced') && level.components[key].userPlaced === true) {
          componentNum += 1
        }
      }
      return componentNum
    } catch (error) {
      return 0
    }
  }
}
