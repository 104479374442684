import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class Package extends ImageElement {
  get suffix() {
    return this.spec ? this.capitalize(this.spec) : 'UnlimCondition'
  }

  get type() {
    return elementType.PACKAGE
  }

  get thread() {
    return this.data.thread
  }

  set thread(thread) {
    this.data.thread = thread
  }

  get delivered() {
    return this.data.delivered
  }

  set delivered(delivered) {
    this.data.delivered = delivered
  }

  get pickup() {
    return this.data.pickup
  }

  set pickup(pickup) {
    this.data.pickup = pickup
  }
}
