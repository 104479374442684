<template>
  <div class="auth-background">
    <v-container justify='center' align='center'>
      <h1>Whoops!</h1>
      <p>The requested page cannot be found.</p>
      <v-btn to='/'>Go Home</v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
