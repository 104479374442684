import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import config from '@/config.json'
import axios from '@/auth/axios'
import knowledge from '@/data/trace_15_knowledge_formatted.json'
import { urls } from '../vars'
/**
 * returns an up to date user pool for callers
 */
function getUpToDateUserPool() {
  return new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: config.aws.poolID,
    ClientId: config.aws.appID
  })
}

const getCognitoUser = () => getUpToDateUserPool().getCurrentUser() || null

export const requestBuilder = (url, params) => {
  const cognitoUser = getCognitoUser()
  if (!cognitoUser) {
    throw new Error('User is either not found or invalid. Please try again.')
  }
  return cognitoUser.getSession((error, result) => {
    if (error || !result) {
      throw new Error(error)
    }
    return axios.post(url, params, { headers: { Authorization: result.idToken.jwtToken } })
  })
}

function authenticateUser(email, password) {
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: email,
    Password: password,
  })

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: getUpToDateUserPool()
  })

  return new Promise((resolve, reject) => cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject
    }))
}

/**
 * Logs current user out of the system. Async function because callbacks were not firing.
 */
async function signOut() {
  const cognitoUser = getCognitoUser()
  if (!cognitoUser) return
  cognitoUser.signOut()
}

function getCurrentActivity() {
  return requestBuilder(urls.GET_CURRENT_ACTIVITY, {})
}

function getActivityByCode(activityCode) {
  return requestBuilder(urls.GET_ACTIVITY_BY_CODE, { activityCode })
}

function setActivityById(activityId) {
  return requestBuilder(urls.SET_ACTIVITY_BY_ID, { activityId })
}

function getPlayer() {
  return requestBuilder(urls.GET_PLAYER)
}
function createPlayer() {
  return requestBuilder(urls.CREATE_PLAYER)
}

async function uploadLogData({ events, metadata }) {
  if (!(events && events.length)) {
    console.error('events field must be set and have at least one item')
  }
  if (!metadata) {
    console.error('metadata field must be set')
  }
  return requestBuilder(urls.UPLOAD_LOG_DATA, { events, metadata })
}

function getLog(level, num_logs = 5) {
  return knowledge
  // TODO: this api is stubbed, get actual data from server
  // return requestBuilder(urls.GET_LOG, { level, num_logs })
}

function getLogComment(log_reference_id) {
  return requestBuilder(urls.GET_LOG_COMMENT, { log_reference_id })
}

/**
 * This is the first function to reset the password and it will send a code
 * to the user. They need to enter that code. Once they enter it, go to the
 * second function changePassword
 * @param {string} email
 */
function startResetPassword(email) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: getUpToDateUserPool()
  })

  return new Promise((resolve, reject) => cognitoUser.forgotPassword({
    onSuccess: resolve,
    onFailure: reject
  }))
}

/**
 * This will update the user's password if the verification code is valid and
 * the password matches the required specifications.
 * @param {string} email
 * @param {string} newPassword
 * @param {string} verificationCode
 */
function finishResetPassword(email, newPassword, confirmationCode) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: email,
    Pool: getUpToDateUserPool()
  })

  return new Promise((resolve, reject) => cognitoUser.confirmPassword(confirmationCode, newPassword, {
    onSuccess: resolve,
    onFailure: reject
  }))
}

/**
 * NOTE: Error checking of email and password should be done before this function
 * is called.
 */
async function createAccount({ email, password, isAdult, didConsent }) {
  const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
    Name: 'email',
    Value: email
  })

  const attributeIsAdult = new AmazonCognitoIdentity.CognitoUserAttribute({
    Name: 'custom:isAdult',
    Value: isAdult
  })
  const attributeDidConsent = new AmazonCognitoIdentity.CognitoUserAttribute({
    Name: 'custom:didConsent',
    Value: didConsent
  })

  return new Promise((resolve, reject) => getUpToDateUserPool()
    .signUp(email, password, [attributeEmail, attributeIsAdult, attributeDidConsent], null, (err, res) => {
      if (err) {
        reject(err)
      }
      resolve(res)
    }))
}

export default {
  authenticateUser,
  createAccount,
  createPlayer,
  finishResetPassword,
  getActivityByCode,
  getCurrentActivity,
  getLog,
  getLogComment,
  getPlayer,
  setActivityById,
  signOut,
  startResetPassword,
  uploadLogData,
}
