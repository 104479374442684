<template>
  <div class="auth-background">
    <div class="auth-ctr">
      <div>
        <h1>Join New Activity</h1>
        <h3>Enter six-digit activity code below.</h3>
        <br>
        <p>To join the default activity with access to all of the levels, enter: 73d371.</p>
        <p><em>If you need a specific activity code, please contact: {{helpEmail}}</em></p>
        <v-form ref="joinStudy" id="join-study" v-model="isValid" @submit.prevent='submit'>
          <v-text-field
            counter
            v-model.trim="code"
            label="Activity Code"
            :rules="[formRules.study, diffCode]"
            required
          ></v-text-field>
          <br>
          <v-btn
            block
            type='submit'
            form='join-study'
            color='primary'
            :disabled="!isValid"
          >Search</v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import config from '@/config.json'
import aws from '@/auth/aws'
import { routeNames } from '../../vars'

export default {
  name: 'JoinStudy',
  data() {
    return {
      code: '',
      isValid: false,
      diffCode: (v) => {
        const currentActivityCode = this.$api.player.getCurrentActivityValueByKey('code')
        return (v !== currentActivityCode) || 'This code is currently active.'
      }
    }
  },
  methods: {
    ...mapActions(['showMessage', 'disableLoadingDialog', 'enableLoadingDialog']),
    submit() {
      this.enableLoadingDialog()
      aws.getActivityByCode(this.code)
        .then(({ data }) => {
          this.$router.push({ name: routeNames.ACTIVITY_OVERVIEW, params: { activityCode: data.activity.code } })
        })
        .catch((error) => {
          this.showMessage(error.message)
          this.$refs.joinStudy.reset()
        })
        .finally(() => this.disableLoadingDialog)
    }
  },
  computed: {
    helpEmail() {
      return config.HELP_EMAIL
    }
  }
}
</script>
