<template>
  <div class='nav-page-ctr'>
    <h1>Forbidden!</h1>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
}
</script>

<style>

</style>
