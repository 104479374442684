/* eslint-disable no-shadow */
function getDefaultState() {
  return {
    didConsent: false,
    isAdult: false,
    email: '',
    primaryPassword: '',
    secondaryPassword: ''
  }
}

const state = getDefaultState()

const getters = {
  accountEmail: (state) => state.email,
  accountDidConsent: (state) => state.didConsent,
  accountIsAdult: (state) => state.isAdult,
  accountPrimaryPassword: (state) => state.primaryPassword,
  accountSecondaryPassword: (state) => state.primaryPassword,
  serializedAccount: (state, getters) => ({
      email: getters.accountEmail,
      didConsent: getters.accountDidConsent.toString(),
      isAdult: getters.accountIsAdult.toString(),
      password: getters.accountPrimaryPassword
    })
}

const actions = {
  setAccountValueByKey: ({ commit }, payload) => {
    commit('setAccountValueByKey', payload)
  },
  destroyAccount: ({ commit }) => {
    commit('destroyAccount')
  }
}

const mutations = {
  setAccountValueByKey: (state, { key, value }) => {
    state[key] = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
