import ImageElement from './ImageElement'
import { elementType } from '../vars'

export default class Exchange extends ImageElement {
  get directions() {
    return this.data.directions
  }

  get current() {
    return this.data.current
  }

  get type() {
    return elementType.EXCHANGE
  }

  get suffix() {
    // return this.capitalize(this.data.spec)
    // TODO: supposedly 'Uni' is the only type, even though there are others in the old asset sheet
    return 'Uni'
  }
}
