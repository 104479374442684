import { scaleLinear } from 'd3'
import Element from './Element'

export default class ImageElement extends Element {
  constructor(data) {
    super(data)
    this.data.primaryImage = data.primaryImage || null
    this.created = Math.round((new Date()).getTime() / 1000)
    this.isGuiElement = data.isGuiElement
  }

  get created() {
    return this.data.created
  }

  set created(created) {
    this.data.created = created
  }

  get capacity() {
    return this.data.capacity
  }

  get spec() {
    return this.data.spec
  }

  set spec(spec) {
    this.data.spec = spec
  }

  get type() {
    return this.data.type
  }

  get userPlaced() {
    return this.data.userPlaced
  }

  get color() {
    return this.data.color
  }

  get locked() {
    return this.data.locked
  }

  get direction() {
    return this.data.direction
  }

  set direction(direction) {
    this.data.direction = direction
  }

  get isGuiElement() {
    return this.data.isGuiElement
  }

  set isGuiElement(flag) {
    this.data.isGuiElement = flag
  }

  get isBoardImageElement() {
    return this.isBoardElement && !this.isGuiElement
  }

  get angle() {
    const scale = scaleLinear().domain([0, 1, 2, 3]).range([270, 0])
    return scale(this.direction)
  }

  get image() {
    return this.primaryImage
  }

  set image(image) {
    console.error('Either set primary or secondary image')
  }

  get primaryImage() {
    return this.data.primaryImage
  }

  set primaryImage(image) {
    this.data.primaryImage = image
  }

  get secondaryImage() {
    return this.data.secondaryImage
  }

  set secondaryImage(image) {
    this.data.secondaryImage = image
  }

  get isBoardElement() {
    const bool = this.data.isBoardElement
    return bool === null ? true : bool
  }

  get isImageElement() {
    return true
  }

  get passed() {
    return this.data.passed
  }

  set passed(passed) {
    this.data.passed = passed
  }

  draw(ctx) {
    if (!this.image) {
      console.error('Image must be bound to element before drawing')
      return
    }
    const y = this.scale.y(this.cell[0])
    const x = this.scale.x(this.cell[1])
    ctx.drawImage(this.image, x, y, this.width, this.height)
    if (this.modifiers && this.modifiers.length) {
      this.modifiers.forEach((mod) => mod.draw(ctx, x, y))
    }
  }

  get name() {
    return `${this.type}${this.suffix}`
  }

  get alt() {
    return `${this.capitalize(this.type)} ${this.suffix}`
  }

  serialize() {
    const data = {
      // Capacity is deprecated
      capacity: this.capacity,
      cell: this.cell,
      color: this.color,
      delay: this.delay,
      delivered: this.delivered,
      direction: this.direction,
      id: this.id,
      // Initial direction might not be used
      initialDirection: this.initialDirection,
      locked: this.locked,
      missed: this.missed,
      passed: this.passed,
      picked: this.picked,
      spec: this.spec,
      type: this.type,
      // For interaction, not for solution checker
      userPlaced: this.userPlaced,
      directionCondition: this.data.directionCondition,
      directionDefault: this.data.directionDefault,
      directionsColors: this.data.directionsColors,
      directionsTypes: this.data.directionsTypes,
      acceptedTypes: this.data.acceptedTypes,
      acceptedColors: this.data.acceptedColors,
      directions: this.data.directions,
      current: this.data.current,
      exchanged: this.data.exchanged,
      value: this.data.value,
      // TODO: confirm this should be included here
      link: this.data.link
    }
    return data
  }
}
