<template>
  <div id="level-view" :style='levelViewStyle'>
    <Level
      :key='selectedLevelString'
      :levelId='selectedLevelString'
      :mapConfig='mapConfig'
    />
  </div>
</template>

<script>
import Level from '@/components/level/Level.vue'

export default {
  name: 'LevelView',
  props: {
    selectedLevelString: {
      required: true,
      type: String
    },
    mapConfig: {
      default: () => ({
        isDisabled: true,
        iconSize: 100,
        width: 454,
        height: 454
      })
    }
  },
  components: {
    Level
  },
  computed: {
    levelViewStyle() {
      return {
        'min-width': `${this.mapConfig.width}px`,
        width: `${this.mapConfig.width}px`,
        height: `${this.mapConfig.height}px`,
        ...(this.mapConfig.isDisabled ? { 'pointer-events': 'none' } : null)
      }
    }
  }
}
</script>

<style>
  #level-view {
    border: 1px black solid;
    position: relative;
    display: block;
  }
</style>
