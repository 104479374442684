<template>
  <v-app>
    <TopBar :class="{'app-bar-disabled no-select': isDraggingElement }" v-model='drawer'/>
    <AuthDrawer :drawer.sync='drawer'/>
    <Message/>
    <LoadingDialog/>
    <v-main>
      <router-view :key="this.$route.fullPath"></router-view>
    </v-main>
  </v-app>

</template>

<script>
import TopBar from '@/components/app/TopBar.vue'
import AuthDrawer from '@/components/app/AuthDrawer.vue'
import Message from '@/components/Message.vue'
import LoadingDialog from '@/components/LoadingDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    TopBar,
    AuthDrawer,
    Message,
    LoadingDialog,
  },
  data() {
    return {
      drawer: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      windowTooSmall: false
    }
  },
  computed: {
    ...mapGetters(['isDraggingElement'])
  }
}
</script>

<style lang='css'>

:root {
  --primary: #CC5827;
  --secondary: #99cfd7;
}

html {
  overflow-y: hidden;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.auth-background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/levelBackground.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.auth-ctr {
  background-color: white;
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 420px;
  min-width: 360px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.std-btn {
  min-width: 150px;
  font-weight: 700;
  background-color: #494f53;
  font-size: 1rem;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.std-btn:hover {
  background-color: grey;
}

.app-bar-disabled {
  pointer-events: none;
}

button[disabled] {
  cursor: not-allowed;
  color: #747677;
}

button[disabled]:hover {
  background-color: #494f53;
}

.capitalize {
  text-transform: capitalize;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-display {
  display: none;
}

.spacer {
  margin: 1rem;
}

.nav-page-ctr {
  margin: 5rem 1rem;
}
</style>
