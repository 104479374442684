/* eslint-disable no-shadow */
import aws from '@/auth/aws'
import Vue from 'vue'
import router from '@/router'
import { logEventClasses } from '@/store/modules/logEvents'

const getDefaultState = () => ({
    metadata: {
      platform: window.navigator.appVersion,
    },
    events: []
  })

const state = getDefaultState()
const getters = {
  logMetadata: (state) => state.metadata,
  logEvents: (state) => state.events,
  isLogMetadataEmpty: (state) => !state.metadata.platform,
  isLogEventsEmpty: (state) => !state.events.length,
  isLogStateStale: (state, getters) => !getters.isLogEventsEmpty,
  lastLogEvent: (state, getters) => getters.logEvents[getters.logEvents.length - 1] || { type: null }
}
const actions = {
  clearLogMetadata: ({ commit }) => {
    commit('clearLogMetadata')
  },
  clearLogEvents: ({ commit }) => {
    commit('clearLogEvents')
  },
  resetLogData: async ({ commit }) => {
    commit('resetLogData')
  },
  uploadLogData: async ({ getters }) => {
    // TODO: sorting probably not needed
    const events = JSON.parse(JSON.stringify(getters.logEvents.sort((x, y) => x.created - y.created)))
    return aws.uploadLogData({ metadata: JSON.parse(JSON.stringify(getters.logMetadata)), events })
  },
  setLogMetadataValueByKey: ({ commit }, payload) => {
    commit('setLogMetadataValueByKey', payload)
  },
  addLogEvent: ({ commit }, data) => {
    if (!Vue.prototype.$api.user.isAuthenticated()) return
    // Do not add log event unless in Level
    if (router.history.current.name !== 'Level') return
    if (!data.type) {
      console.error('Type must be set')
      return
    }
    let Constructor = logEventClasses[data.type]
    if (!Constructor) {
      Constructor = logEventClasses.BASE
    }
    const newLogEvent = new Constructor(data)
    commit('addLogEvent', newLogEvent)
  },
}
const mutations = {
  setLogMetadataValueByKey(state, { key, value }) {
    Vue.set(state.metadata, key, value)
  },
  addLogEvent(state, event) {
    state.events.push(event.data)
  },
  clearLogMetadata(state) {
    state.metadata = {}
  },
  clearLogEvents(state) {
    state.events = []
  },
  resetLogData(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
