export const alphaNumeric = Array.from('abcdefghijklmnopqrstuvwxyz0123456789')
export const alphabet = Array.from('abcdefghijklmnopqrstuvwxyz')

export function* IDGenerator(_idSize, set) {
  let idSize = _idSize
  if (idSize < 1) {
    /* eslint no-console: "off" */
    console.error('idSize cannot be less than 1. Defaulted value to 1.')
    idSize = 1
  }

  for (let i = 0; i < set.length; ++i) {
    if (idSize === 1) {
      yield set[i]
    } else {
      for (const id of IDGenerator(idSize - 1, set)) {
        yield `${set[i]}${id}`
      }
    }
  }
}
