import ImageElement from './ImageElement'
import DeliveryCounter from './DeliveryCounter'
import PickupDeliveryModifier from './PickupDeliveryModifier'
import { elementType } from '../vars'

/**
 * Type is determined by the value of the spec attribute.
 */

export default class Delivery extends ImageElement {
  constructor(data) {
    super(data)
    this.showHighlight = false
    this.originColor = false
  }

  get suffix() {
    return this.capitalize(this.spec)
  }

  get type() {
    return elementType.DELIVERY
  }

  get delivered() {
    return this.data.delivered
  }

  set delivered(delivered) {
    this.data.delivered = delivered
  }

  get passed() {
    return this.data.passed
  }

  get isActionable() {
    return true
  }

  set passed(passed) {
    this.data.passed = passed
  }

  set originColor(flag) {
    this.data.originColor = flag
  }

  set showHighlight(flag) {
    this.data.showHighlight = flag
  }

  get modifiers() {
    const modifiers = []
    const data = {
      totalNumber: 1,
      targetNumber: 2,
      parent: this
    }
    modifiers.push(new DeliveryCounter(data))
    if (this.data.showHighlight) {
      const hData = {
        parent: this
      }
      const mod = new PickupDeliveryModifier(hData)
      if (this.data.originColor) {
        mod.color = 'orange'
      } else {
        mod.color = 'yellow'
      }
      modifiers.push(mod)
    }
    return modifiers
  }
}
