<template>
  <div class='action-bar' @mousemove='mouseMove' @mouseup='mouseUp'>
    <KeyMap class='no-select'/>
    <div class="playback-buttons">
      <v-btn
        class='no-select playback-btn reset-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => stopSimulation()"
      >Reset</v-btn>
      <v-btn
        v-if='!isSimulationPaused'
        class='no-select playback-btn pause-play-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => pauseSimulation()"
      ><v-icon class='action-btn-icon'>mdi-pause</v-icon>Pause</v-btn>
      <v-btn
        v-else
        class='no-select playback-btn pause-play-btn mr-3'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => unpauseSimulation()"
      ><v-icon class='action-btn-icon'>mdi-play</v-icon>Play</v-btn>
      <v-btn
        class='no-select playback-btn'
        :disabled='!isSimulationActive'
        :small="$vuetify.breakpoint.mdAndDown"
        @click.prevent="() => skipSimulation()"
      ><v-icon class='playback-btn-icon'>mdi-skip-next</v-icon>Skip</v-btn>
    </div>
    <div class='simulation-buttons'>
      <v-btn
        id='TEST_BUTTON'
        class='no-select mr-4'
        @click.prevent="() => handleSimulation('TEST')"
        :disabled='isSimulationActive'
        :color='getActionBtnColor(isTestDisabled())'
        :large="$vuetify.breakpoint.lgAndUp"
      ><v-icon class='action-btn-icon'>{{getActionBtnIcon(isTestDisabled())}}</v-icon>TEST</v-btn>
      <v-btn
        id='SUBMIT_BUTTON'
        class='no-select'
        :large="$vuetify.breakpoint.lgAndUp"
        :disabled='isSimulationActive'
        @click.prevent="() => handleSimulation('SUBMIT')"
        :color='getActionBtnColor(isSubmitDisabled())'
      ><v-icon class='action-btn-icon'>{{getActionBtnIcon(isSubmitDisabled())}}</v-icon>SUBMIT</v-btn>
      <div class='action-divider'></div>
      <v-btn
        class='no-select'
        @click.prevent='handleReflection'
        :disabled='isReflectionDisabled()'
        :small="$vuetify.breakpoint.mdAndDown"
        :class="{'action-btn-disabled': isReflectionDisabled()}"
      ><v-icon class='action-btn-icon'>mdi-animation-play</v-icon>RESULTS</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { actionType, elementType } from '@/vars'
import KeyMap from '@/components/level/KeyMap.vue'

export default {
  name: 'ActionBar',
  data() {
    return {
      draggedEl: null,
      startCell: null
    }
  },
  computed: {
    ...mapGetters([
      'isSimulationEmpty',
      'reflectionContent',
      'isSimulationActive',
      'isSimulationPaused',
      'hasTestBeenRunSuccessfully',
      'shouldCheckTutorialRequiredAction',
      'isSimulationRequiredForTutorialStep',
      'hasReflectionContentBeenSet',
      'isTutorialActive',
      'isDraggingElement',
      'select',
      'draggedElementPosition'
      ]),
  },
  components: {
    KeyMap
  },
  methods: {
    ...mapActions([
      'showMessage',
      'runSimulation',
      'activateReflectionDialog',
      'pauseSimulation',
      'skipSimulation',
      'unpauseSimulation',
      'stopSimulation',
      'checkTutorialRequiredAction',
      'draw',
      'setDraggedElementPosition',
      'setIsDraggingElement'
    ]),
    mouseUp(e) {
      this.startCell = this.draggedElementPosition
      const el = this.select(e.y, e.x)
      this.draggedEl = el
      this.draggedEl.coordinates = [e.y, e.x]
      if (!this.isSimulationActive && this.isDraggingElement) {
          this.setIsDraggingElement(false)
          this.returnDraggedEl()
          this.showMessage('Elements must be placed on path!')
      }
      this.draw()
    },
    mouseMove(e) {
      const { y, x } = e
      const el = this.select(y, x)
      // If an element is being dragged
      if (this.isDraggingElement) {
        this.draggedEl = el
        this.draggedEl.coordinates = [y, x]
      }
      this.draw()
    },
    returnDraggedEl() {
      this.draggedEl.cell = this.startCell
      this.startCell = null
      this.setDraggedElementPosition(null)
      this.resetDraggedEl()
    },
    resetDraggedEl() {
      this.draggedEl = null
    },
    handleSimulation(type) {
      if (type === 'SUBMIT' && !this.hasTestBeenRunSuccessfully) {
        this.showMessage('You must complete a successful test before submitting.')
      }
      if (type === 'SUBMIT' && this.isSubmitDisabled()) return
      if (type === 'TEST' && this.isTestDisabled()) return
      if (this.isSimulationRequiredForTutorialStep) {
        this.runSimulation(type)
      } else if (this.shouldCheckTutorialRequiredAction) {
        const target = type === actionType.TEST ? elementType.TEST_BUTTON : elementType.SUBMIT_BUTTON
        this.checkTutorialRequiredAction({ type: actionType.BUTTON_CLICK, target })
      } else {
        this.runSimulation(type)
      }
    },
    handleReflection() {
      if (!this.isSimulationActive) {
        this.activateReflectionDialog()
      } else {
        this.showMessage('You must fully complete a test or submission to get reflection content.')
      }
    },
    getActionBtnColor(flag) {
      return flag ? 'red' : 'green'
    },
    getSubmitIcon() {
      return this.isSubmitDisabled() ? 'mdi-lock' : 'mdi-lock-open-outline'
    },
    getActionBtnIcon(flag) {
      return flag ? 'mdi-lock' : 'mdi-lock-open-outline'
    },
    isSubmitDisabled() {
      if (!this.hasTestBeenRunSuccessfully) {
        return true
      }
      if (this.isSimulationActive) {
        return true
      }
      return false
    },
    isTestDisabled() {
      if (this.isSimulationActive) {
        return true
      }
      return false
    },
    isReflectionDisabled() {
      if (this.isTutorialActive) {
        return true
      }
      if (!this.hasReflectionContentBeenSet) {
        return true
      }
      if (this.isSimulationActive) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.action-bar {
  height: 100px;
  width: 100%;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
}
.simulation-buttons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.action-btn-icon {
  margin-right: 0.5rem;
}
.action-divider {
  height: 25px;
  margin: 0 1.5rem;
  border: 1px solid rgb(65, 65, 65);
}

.pause-play-btn {
  width: 100px;
}
.pause-play-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.playback-buttons {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.playback-btn-icon {
  margin-right: 0.2rem;
}
</style>
