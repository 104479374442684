import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: '#CC5827',
        secondary: '#99cfd7',
        accent: '#494f53'
      }
    }
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  }
}

export default new Vuetify(options)
