/**
 *    Script:         boardUtils.js
 *    Description:    This script holds utility functions for the
 *                    solution checker, mostly fore expediting
 *                    finding various objects
 *
 *    Author:         Boyd Fox                    Date: 6/15/2020
 *    Notes:
 */

/**
 * getDirection() returns the cell object located in
 * a given direction from a given cell
 */
export function getDirection(direction, map, cell) {
  let r = 0
  let c = 0
  switch (direction) {
    case 1: r = [cell[0]]; c = [cell[1] + 1]; break
    case 2: r = [cell[0] + 1]; c = [cell[1]]; break
    case 3: r = [cell[0]]; c = [cell[1] - 1]; break
    case 4: r = [cell[0] - 1]; c = [cell[1]]; break
  }

  if (r >= 0 && c >= 0 && r < map.length && c < map[0].length) {
    return map[r][c]
  }

  return null
}

/**
 * threadMoveCheck() returns a boolen value indicating if
 * all threads have
 * a) exhuasted their current movement budgets, or
 * b) slept (remained inactive) for too long
 * returns false if there are still moves to be made
 * else, returns true
 */
export function threadMoveCheck(board, threads, starvationTimer) {
  let starve = false
  let budget = true
  for (let index = 0; index < threads.length; index += 1) {
    const thread = board.components[threads[index]]
    if (thread.starvation >= starvationTimer) {
      starve = true
      break
    } else if (thread.budget > 0 && !thread.stopped) {
      budget = false
    }
  }

  if (starve) {
    return true
  } if (budget) {
    return true
  }

  return false
}

/**
 * getSemaphores() returns an array of all semaphore components
 * on the board
 */
export function getThreadsToMove(_board, threads, maxStarvation, threadBudget, rng) {
  const threadsToMove = []
  const board = _board
  let thread = null
  let index = 0

  // if our current list is valid
  if (!threadMoveCheck(board, threads, maxStarvation)) {
    for (index = 0; index < threads.length; index += 1) {
      thread = board.components[threads[index]]
      if (thread.budget > 0 && !thread.stopped) {
        threadsToMove.push(threads[index])
      }
    }
  } else {
    const threadsCopy = []
    const threadsMustMove = []
    for (index = 0; index < threads.length; index += 1) {
      thread = board.components[threads[index]]
      thread.budget = 0
      if (thread.starvation >= maxStarvation) {
        threadsMustMove.push(index)
      } else {
        threadsCopy.push(index)
      }
    }

    const randomThreadCount = Math.floor(rng() * threads.length) + 1 - threadsMustMove.length
    for (index = 0; index < threadsMustMove.length; index += 1) {
      threadsToMove.push(threads[threadsMustMove[index]])
    }

    for (index = 0; index < randomThreadCount; index += 1) {
      const randThread = Math.floor(rng() * threadsCopy.length)
      threadsToMove[index] = threads[threadsCopy[randThread]]
      board.components[threadsToMove[index]].budget = Math.floor(rng() * threadBudget)
      threadsCopy.splice(randThread, 1)
    }
  }

  return threadsToMove
}

/**
 * getComponents() returns an array of all of the target
 * component type on the board
 */
export function getComponents(components, type) {
  const comps = []
  for (const prop in components) {
    if (Object.prototype.hasOwnProperty.call(components, prop)) {
      if (components[prop].type === type) {
        comps.push(prop)
      }
    }
  }
  return comps
}

/**
 * getComponent() returns a specified type of component that is
 * at the location of a given cell
 * If there is not such a component at that location, it
 * returns null instead
 */
export function getComponent(board, cell, type) {
  for (let index = 0; index < cell.components.length; index += 1) {
    if (board.components[cell.components[index]].type === type) {
      return board.components[cell.components[index]]
    }
  }
  return null
}

/**
 * @param {any} thread
 * @param {any} delivery
 * @param {any} components
 */
export function getDeliveredPackageIndex(thread, delivery, components) {
  const indices = []
  let i = 0
  if (delivery.acceptedColors.length <= 0) {
    for (i = 0; i < thread.payload.length; i++) {
      indices.push(i)
    }
  } else {
    for (i = 0; i < thread.payload.length; i++) {
      if (delivery.acceptedColors.includes(components[thread.payload[i].pickup].color)) {
        indices.push(i)
      }
    }
  }

  if (delivery.acceptedTypes.length > 0) {
    for (i = 0; i < indices.length; i++) {
      if (!delivery.acceptedTypes.includes(components[thread.payload[i].pickup].spec)) {
        indices.splice(i, 1)
        i -= 1
      }
    }
  }

  return indices
}

/**
 * createAction() creates an returns an action object
 * THIS SHOULD BE A CLASS
 * structured properly for the front end
 */
export function createAction(
  idGenerator,
  type,
  timestep,
  component,
  mutations,
) {
  const mkv = []
  for (const prop in mutations) {
    if (Object.prototype.hasOwnProperty.call(mutations, prop)) {
      mkv.push({ key: prop, value: mutations[prop] })
    }
  }

  const action = {
    id: idGenerator.next().value,
    type,
    timestep,
    component,
    mutations: mkv,
  }

  return action
}

/**
 * An implementation of fisher-yates shuffle
 * Randomizes the order of an array
 * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 * https://javascript.info/task/shuffle
 * */
export function shuffle(array, rng) {
  for (let index = array.length - 1; index > 0; index--) {
    const rand = Math.floor(rng() * (index + 1))
    const element = array[index]
    array[index] = array[rand]
    array[rand] = element
  }
}
