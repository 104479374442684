<template>
  <div class="dim-background">
    <div class="dim-content">
      <h1>This screen is too small to play Parallel.</h1>
      <p>Your browser window must have a width of at least <strong>{{widthLimit}}px</strong> and a height of at least <strong>{{heightLimit}}px</strong>.</p>
      <p>If on a desktop computer, try decreasing the zoom level of the browser and refreshing the page. If you need help or think this is an error, please contact {{helpMail}}.</p>
    </div>
  </div>
</template>

<script>
import config from '@/config.json'

export default {
  name: 'DimensionError',
  data() {
    return {
      widthLimit: config.dimensionLimit.width,
      heightLimit: config.dimensionLimit.height,
      helpMail: config.HELP_EMAIL
    }
  }
}
</script>

<style lang='css'>
  .dim-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dim-content {
    font-size: 22px;
    position: fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
