import Element from './Element'

export default class PathElement extends Element {
  constructor(data) {
    super(data)
    this.cell = data.coords || data.cell
  }

  get layout() {
    return this.data.layout
  }

  get orientation() {
    return this.data.orientation
  }

  get isPathElement() {
    return true
  }

  get path() {
    console.error('Not implemented')
    return ''
  }

  get fillStyle() {
    return this.data.fillStyle || 'black'
  }

  set fillStyle(fillStyle) {
    this.data.fillStyle = fillStyle
  }

  get getTrackModifiers() {
    const modifiers = this.modifiers[0]
    const x = this.scale.x(this.cell[1]) + this.radius
    const y = this.scale.y(this.cell[0]) + this.radius
    return { modifiers, x, y }
  }

  // eslint-disable-next-line no-unused-vars
  draw(ctx) {
    ctx.save()
    const x = this.scale.x(this.cell[1]) + this.radius
    const y = this.scale.y(this.cell[0]) + this.radius
    ctx.translate(x, y)
    const p = new Path2D(this.path)
    ctx.rotate(this.angle * (Math.PI / 180))
    ctx.translate(-this.radius, -this.radius)
    ctx.fillStyle = this.fillStyle
    ctx.fill(p)
    ctx.restore()
  }

  serialize() {
    const data = {
      color: this.data.color,
      components: this.data.components,
      coords: this.data.cell,
      directions: this.data.directions,
      id: this.id,
      layout: this.data.layout,
      orientation: this.data.orientation
    }
    return data
  }
}
