<template>
    <v-text-field
      v-model='passwordLocal'
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[formRules.generic, formRules.password]"
      class='password-field'
      :type="show ? 'text' : 'password'"
      name='input-10-1'
      :label='labelLocal'
      @click:append="toggleShow"
      required
    ></v-text-field>
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    type: {
      type: String,
      required: false,
      default: 'primary'
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    labelLocal() {
      if (this.label) {
        return this.label
      }
      return this.type === 'primary' ? 'Enter Password' : 'Re-enter Password'
    },
    passwordLocal: {
      get() {
        return this.password
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    toggleShow() {
      this.$emit('toggle-show')
    }
  }
}
</script>

<style>
  .password-field .v-text-field__details {
    height: 2rem !important;
  }
</style>
