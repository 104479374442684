import ImageElement from './ImageElement'
import PickupDeliveryModifier from './PickupDeliveryModifier'
import { elementType } from '../vars'

export default class Pickup extends ImageElement {
  constructor(data) {
    super(data)
    this.showHighlight = false
    this.originColor = false
  }

  get suffix() {
    return this.capitalize(this.spec)
  }

  get type() {
    return elementType.PICKUP
  }

  get name() {
    // Pickup assets are the same as package assets
    return `package${this.suffix}`
  }

  get alt() {
    return `Package ${this.suffix}`
  }

  get picked() {
    return this.data.picked
  }

  get isActionable() {
    return true
  }

  set picked(picked) {
    this.data.picked = picked
  }

  get passed() {
    return this.data.passed
  }

  set passed(passed) {
    this.data.passed = passed
  }

  set originColor(flag) {
    this.data.originColor = flag
  }

  set showHighlight(flag) {
    this.data.showHighlight = flag
  }

  get modifiers() {
    const modifiers = []
    if (this.data.showHighlight) {
      const data = {
        parent: this
      }
      const mod = new PickupDeliveryModifier(data)
      if (this.data.originColor) {
        mod.color = 'orange'
      } else {
        mod.color = 'yellow'
      }
      modifiers.push(mod)
    }
    return modifiers
  }
}
