<template>
  <v-app-bar id='top-bar' absolute dense flat>
    <v-toolbar-title><v-btn :disabled="isAppLoading" @click.prevent='handleNavigate'>Parallel</v-btn></v-toolbar-title>
    <div class='level-header-ctr' v-if="rt.name==='Level'">
      <div class="level-id">Level {{levelId}} </div>
      <div class='level-goal'>Goal: {{goal}}</div>
    </div>
    <v-spacer></v-spacer>
    <v-btn v-if='$api.user.isAuthenticated()' icon @click='drawerLocal= !drawerLocal'>
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <v-btn v-else color='primary' small to='/login/'>Log In</v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { routeNames } from '../../vars'

export default {
  name: 'TopBar',
  methods: {
    handleNavigate() {
      if (this.rt.name === 'Level' || this.$api.user.isAuthenticated()) {
        this.rtr.push({ name: routeNames.LEVEL_SELECT })
      } else if (this.rt.name !== 'Landing') {
        this.rtr.push({ name: routeNames.LANDING })
      }
    }
  },
  computed: {
    ...mapGetters(['metadata', 'isAppLoading']),
    goal() {
      if (!this.metadata) return ''
      return this.metadata.goal.string
    },
    levelId() {
      return this.rt.params.level
    },
    drawerLocal: {
      get() {
        return this.drawer
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>

<style lang="css" scoped>
.level-header-ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.level-id {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
}
.level-goal {
  font-size: 1rem;
  margin-left: 2rem;
}
</style>
